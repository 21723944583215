import { useNuxtApp } from "#imports";

export function generateLink(url: string) {
  const { $localePath } = useNuxtApp();

  const regexHttp = new RegExp("^(http|https)://");
  if (url && !regexHttp.test(url) && url.charAt(0) !== "/") {
    url = `/${url}`;
  }
  // Path localisation if not URL
  url = !isURL(url) ? $localePath(url) : url;

  return url;
}

/**
 * Check if the string is a valid URL
 *
 * @param string
 */
export function isURL(string: string): boolean {
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(string);
}
