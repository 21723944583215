<template>
  <div class="flex items-center" :class="containerClass">
    <Icon :name="iconName" size="20" class="mr-2 flex-shrink-0" />
    <span class="flex-grow flex-1 min-w-0 pt-0.5 break-words">{{ message }}</span>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps({
  type: {
    type: String,
    default: 'info',
    validator: (value: string) => ['success', 'warning', 'info', 'error'].includes(value),
  },
  message: {
    type: String,
    default: 'Placeholder',
  },
});

const containerClass = computed(() => {
  switch (props.type) {
    case 'success':
      return 'border border-green-900 bg-green-50 ring-4 ring-green-100 ring-inset rounded-md my-4 py-3 px-4 text-green-700 font-medium text-sm';
    case 'warning':
      return 'border border-yellow-900 bg-yellow-50 ring-4 ring-yellow-100 ring-inset rounded-md my-4 py-3 px-4 text-yellow-700 font-medium text-sm';
    case 'error':
      return 'border border-red-900 bg-red-50 ring-4 ring-red-100 ring-inset rounded-md my-4 py-3 px-4 text-red-700 font-medium text-sm';
    default:
      return 'border border-blue-900 bg-blue-50 ring-4 ring-blue-100 ring-inset rounded-md my-4 py-3 px-4 text-blue-700 font-medium text-sm';
  }
});

const iconName = computed(() => {
  switch (props.type) {
    case 'success':
      return 'tabler:circle-check';
    case 'warning':
      return 'tabler:alert-circle';
    case 'error':
      return 'tabler:alert-triangle';
    default:
      return 'tabler:info-circle';
  }
});
</script>
