export const useAlertStore = defineStore('alert', () => {
  const alertMessage = ref<{ message: string; type: string; show: boolean }>({
    message: '',
    type: '',
    show: false,
  });

  const setAlert = (message: string, type: string) => {
    alertMessage.value = { message, type, show: true };
  };

  const clearAlert = () => {
    alertMessage.value = { message: '', type: '', show: false };
  };

  return {
    alertMessage,
    setAlert,
    clearAlert,
  };
});
