<template>
  <div class="border-t flex justify-center items-center py-4">
    <div class="container flex items-center justify-center gap-x-12">
      <HeadlessMenu class="flex" as="div" v-slot="{ open }">
        <HeadlessMenuButton class="w-full capitalize menu-underline focus:outline-non ui-open:font-bold">
          {{ t('nav.shop') }}
        </HeadlessMenuButton>
        <Teleport to="body">
          <div v-if="open" class="fixed top-0 bottom-0 left-0 h-screen w-full z-40 bg-black/40"></div>
        </Teleport>
        <transition name="slide-from-top">
          <HeadlessMenuItems
            v-if="open"
            class="bg-white z-[50] absolute left-0 right-0 mt-[30px] py-8 shadow-md focus:outline-none border"
          >
            <div class="lg:mx-[5%] xl:mx-[10%] py-1">
              <div class="grid grid-cols-12 justify-center items-center">
                <HeadlessTransitionChild
                  enter="transition-opacity ease-linear duration-150 delay-150 "
                  enter-from="opacity-0"
                  enter-to="opacity-100"
                  leave="transition-opacity ease-linear duration-150 delay-150"
                  leave-from="opacity-100"
                  leave-to="opacity-0"
                  class="col-span-4"
                >
                  <div class="grid grid-cols-2 items-start gap-4">
                    <HeadlessMenuItem
                      v-slot="{ close }"
                      class="hover:bg-gray-100 group flex flex-col px-2 py-2 text-2xl font-medium cursor-pointer col-span-2"
                    >
                      <div>
                        <NuxtLink
                          :to="generateLink('/shop/')"
                          class="after:w-5 after:h-[1px] after:bg-gray-200 after:block"
                          @click="close"
                        >
                          <a @click="close">{{ t('shop.all') }}</a>
                        </NuxtLink>
                      </div>
                    </HeadlessMenuItem>
                    <HeadlessMenuItem
                      v-for="category in categories"
                      :key="category?.id"
                      v-slot="{ close }"
                      class="hover:bg-gray-100 group flex flex-col justify-center px-2 text-xl font-medium cursor-pointer"
                    >
                      <div>
                        <NuxtLink :to="generateLink('/shop/?category=' + category.slug)" @click="close">
                          <a @click="close">{{ category.name }}</a>
                        </NuxtLink>
                      </div>
                    </HeadlessMenuItem>
                  </div>
                </HeadlessTransitionChild>
                <LazyHeadlessMenuItem
                  v-slot="{ close }"
                  v-if="navProducts?.data"
                  class="col-span-8 flex gap-x-4 pl-12 w-full"
                  :class="navProducts.data.length > 1 ? 'justify-center' : 'justify-start'"
                >
                  <div>
                    <SharedNavProductCard
                      :product="product"
                      v-for="product in navProducts.data?.slice(0, 3)"
                      :key="product.id"
                      @click="
                        () => {
                          close();
                          gtm?.trackEvent({
                            event: 'view_item',
                            navigated_from: 'Desktop Shop Navigation Menu',
                            ecommerce: {
                              currency: 'AED',
                              value: formatPrice(product.price),
                              items: [
                                {
                                  item_id: product.id,
                                  item_sku: product.sku,
                                  item_name: product.meta_title['en'],
                                  item_category: product.categories?.[0]?.name,
                                  item_category2: product.categories?.[1]?.name,
                                  item_category3: product.categories?.[2]?.name,
                                  price: formatPrice(product.price),
                                  discount: formatPrice(product.sale_price),
                                  quantity: 1,
                                },
                              ],
                            },
                          });

                          viewProduct({
                            LanguageCode: locale,
                            ProductName: product.name,
                            ProductID: product.id,
                            SKU: product.sku,
                            Categories: product.categories?.map((category) => category.name).join(', '),
                            ImageURL: product.cover,
                            URL: route.fullPath,
                            Brand: 'Biogena',
                            Price: product.price,
                            PriceCurrency: 'AED',
                            CompareAtPrice: product.sale_price,
                          });
                        }
                      "
                    />
                  </div>
                </LazyHeadlessMenuItem>
              </div>
            </div>
          </HeadlessMenuItems>
        </transition>
      </HeadlessMenu>
      <ul class="flex justify-center items-center gap-x-12">
        <li class="menu-underline">
          <NuxtLink :to="generateLink('/why-biogena')"> {{ t('nav.about') }}</NuxtLink>
        </li>
        <li class="menu-underline">
          <NuxtLink :to="generateLink('/stories')"> {{ t('nav.stories') }} </NuxtLink>
        </li>
        <li class="menu-underline">
          <NuxtLink :to="generateLink('/blog')"> {{ t('nav.blog') }} </NuxtLink>
        </li>
        <li class="menu-underline">
          <NuxtLink :to="generateLink('/contact')"> {{ t('nav.contact') }} </NuxtLink>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ProductsFetchResponse } from '~/interfaces/Product';
import { generateLink } from '~/utils/route';

const gtm = useGtm();
const { locale, t } = useI18n();
const route = useRoute();

const { viewProduct } = useKlaviyo();

const categoryStore = useCategoryStore();
const { categories } = storeToRefs(categoryStore);

const { data: navProducts } = await useFetch<ProductsFetchResponse>('/api/products/tags/' + 'nav-products', {
  key: 'nav-products',
  headers: {
    'Accept-Language': locale.value.split('-')[0],
  },
  transform(input) {
    return {
      ...(input as any),
      fetchedAt: new Date(),
      language: locale.value,
    };
  },
  getCachedData(key, nuxtApp) {
    const data = nuxtApp.payload.data[key] || nuxtApp.static.data[key];

    if (!data) {
      return;
    }

    const expirationDate = new Date(data.fetchedAt);
    expirationDate.setTime(expirationDate.getTime() + 3600 * 1000);
    const isExpired = expirationDate.getTime() < Date.now();

    if (isExpired) {
      return;
    }

    if (locale.value !== data.language) {
      return;
    }

    return data;
  },
});

if (navProducts.value?.data && navProducts?.value?.data?.length > 1) {
  navProducts.value?.data?.sort(() => Math.random() - 0.5);
}
</script>

<style scoped>
.slide-from-top-enter-active {
  animation: border-anim 0.5s;
}

@keyframes border-anim {
  0% {
    border-top: 32px solid #fff;
  }
  100% {
    border-top: 1px solid #fff;
  }
}

.menu-underline {
  position: relative;
  text-decoration: none;
}

.menu-underline::after {
  content: '';
  display: block;
  height: 1px;
  width: 0px;
  opacity: 1;
  transition: width 0.3s ease-in-out;
  @apply bg-primary-1000;
}

.menu-underline:hover::after {
  width: 100%;
  opacity: 1;
}
</style>
