import axios from 'axios';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  const http = axios.create({
    baseURL: config.public.API_URL,
    headers: {
      common: {
        'Content-Type': 'application/json',
      },
    },
  });

  http.interceptors.request.use(
    (config) => {
      config.headers.Authorization = 'Bearer ' + getCustomCookie('token');
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  return {
    provide: {
      http: http,
    },
  };
});
