import type { Settings } from '~/interfaces/Settings';

export const useSettingsStore = defineStore(
  'settings',
  () => {
    const settings = ref<Settings | null>();
    const ip = ref<string | null>();
    const userCountry = ref({
      countryCode: '',
      country: '',
    });
    const userCurrency = ref<string>('AED');

    const setSettings = (data: Settings) => {
      settings.value = data;
    };

    const setIp = (data: string) => {
      ip.value = data;
    };

    const setUserCountry = (data: { countryCode: string; country: string }) => {
      userCountry.value = {
        countryCode: data.countryCode,
        country: data.country,
      };
    };

    const setCurrency = (currency: string) => {
      userCurrency.value = currency;
    };

    return {
      ip,
      userCountry,
      userCurrency,
      settings,
      setSettings,
      setCurrency,
      setIp,
      setUserCountry,
    };
  },
  {
    persist: true,
  },
);
