import type {
  KlaviyoAddToCart,
  KlaviyoAddToWishlist,
  KlaviyoStartCheckout,
  KlaviyoViewProduct,
} from '~/interfaces/Klaviyo';

export const useKlaviyo = () => {
  if (typeof window !== 'undefined') {
    let klaviyo = window.klaviyo || [];
    const { locale } = useI18n();

    const identify = (
      email: string,
      user?: { $first_name: string; $last_name: string; $phone_number: string; 'Preferred Language': string },
    ) => {
      klaviyo.push([
        'identify',
        {
          email: email,
          $first_name: user?.$first_name,
          $last_name: user?.$last_name,
          $phone_number: user?.$phone_number,
          'Preferred Language': locale.value,
        },
      ]);
    };

    const viewProduct = (product: KlaviyoViewProduct) => {
      klaviyo.push(['track', 'Viewed Product', product]);
    };

    const addToCart = (product: KlaviyoAddToCart) => {
      klaviyo.push(['track', 'Added to Cart', product]);
    };

    const startCheckout = (checkout: KlaviyoStartCheckout) => {
      klaviyo.push(['track', 'Started Checkout', checkout]);
    };

    const addToWishlist = (product: KlaviyoAddToWishlist) => {
      klaviyo.push(['track', 'Added to Wishlist', product]);
    };

    return { identify, viewProduct, addToCart, addToWishlist, startCheckout };
  } else {
    return {
      identify: () => {},
      viewProduct: () => {},
      addToCart: () => {},
      addToWishlist: () => {},
      startCheckout: () => {},
    };
  }
};
