<template>
  <div
    v-if="announcements && announcements.data?.length > 0"
    class="bg-primary-1000 text-center text-white text-sm font-medium py-2 z-50"
  >
    <ClientOnly>
      <Swiper
        ref="announcementSwiper"
        class="announcement-swiper max-w-lg"
        :modules="[SwiperAutoplay, SwiperNavigation]"
        :slides-per-view="1"
        :navigation="announcements.data?.length > 1 ? true : false"
        loop
        :autoplay="{ delay: 3000 }"
      >
        <SwiperSlide v-for="announcement in announcements.data" :key="announcement.id">
          <p class="text-center rtl:text-center" v-html="announcement.message[splitLocale]" />
        </SwiperSlide>
      </Swiper>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
const { t, locale } = useI18n();
const splitLocale = locale.value.split('-')[0];

const { data: announcements } = await useFetch<{
  data: {
    id: string;
    message: Record<string, string>;
  }[];
}>('/api/announcements', {
  headers: {
    'Accept-Language': splitLocale,
  },
});
</script>

<style>
.announcement-swiper .swiper-button-next::after,
.announcement-swiper .swiper-button-prev::after {
  background: transparent !important;
  color: white !important;
}
</style>
