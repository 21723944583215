import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93ILszah2VNpMeta } from "/app/pages/[...slug].vue?macro=true";
import { default as indexQWKFaMHH2OMeta } from "/app/pages/account/index.vue?macro=true";
import { default as detailsTL0xthZoXGMeta } from "/app/pages/account/orders/details.vue?macro=true";
import { default as indexv0iIYE4xFlMeta } from "/app/pages/account/orders/index.vue?macro=true";
import { default as wishlistzQE62vNi5oMeta } from "/app/pages/account/wishlist.vue?macro=true";
import { default as biogena_45diamondsyRXSkVE1wzMeta } from "/app/pages/biogena-diamonds.vue?macro=true";
import { default as _91blog_93SLRfK6x4QJMeta } from "/app/pages/blog/[blog].vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as index7ZtduOIoVXMeta } from "/app/pages/checkout/index.vue?macro=true";
import { default as order_45confirmationFeXGmBtLFoMeta } from "/app/pages/checkout/order-confirmation.vue?macro=true";
import { default as contactV6oPJjs9RyMeta } from "/app/pages/contact.vue?macro=true";
import { default as faqIMkmXQpKKaMeta } from "/app/pages/faq.vue?macro=true";
import { default as forgot_45password1UbT4qDpFeMeta } from "/app/pages/forgot-password.vue?macro=true";
import { default as indexgK5VicK7y2Meta } from "/app/pages/login/index.vue?macro=true";
import { default as magicyDluANufd1Meta } from "/app/pages/login/magic.vue?macro=true";
import { default as oauthPYGhATsrucMeta } from "/app/pages/login/oauth.vue?macro=true";
import { default as privacy_45policyOPxqa3IsSYMeta } from "/app/pages/privacy-policy.vue?macro=true";
import { default as _91product_93kDQODyJrKGMeta } from "/app/pages/product/[product].vue?macro=true";
import { default as registermu8R7rSP5uMeta } from "/app/pages/register.vue?macro=true";
import { default as reset_45password52TOS9kgCUMeta } from "/app/pages/reset-password.vue?macro=true";
import { default as return_45policyFD37NE0ujqMeta } from "/app/pages/return-policy.vue?macro=true";
import { default as indexNvxd1fRHrwMeta } from "/app/pages/shop/index.vue?macro=true";
import { default as storieseEdR1xXEeJMeta } from "/app/pages/stories.vue?macro=true";
import { default as _91story_93S5SRVkGtmDMeta } from "/app/pages/story/[story].vue?macro=true";
import { default as terms_45and_45conditionszK6eZNsNEXMeta } from "/app/pages/terms-and-conditions.vue?macro=true";
import { default as track_45orderv0hHwuF2jbMeta } from "/app/pages/track-order.vue?macro=true";
import { default as warningsRoD48qvL49Meta } from "/app/pages/warnings.vue?macro=true";
import { default as why_45biogenaKNzMlQpgD4Meta } from "/app/pages/why-biogena.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___ar",
    path: "/ar/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___en-sa",
    path: "/en-sa/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___ar-sa",
    path: "/ar-sa/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___en-qa",
    path: "/en-qa/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___ar-qa",
    path: "/ar-qa/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___en-kw",
    path: "/en-kw/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___ar-kw",
    path: "/ar-kw/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___en-om",
    path: "/en-om/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___ar-om",
    path: "/ar-om/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___en-bh",
    path: "/en-bh/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___ar-bh",
    path: "/ar-bh/:slug(.*)*",
    component: () => import("/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/account",
    meta: indexQWKFaMHH2OMeta || {},
    component: () => import("/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___ar",
    path: "/ar/account",
    meta: indexQWKFaMHH2OMeta || {},
    component: () => import("/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en-sa",
    path: "/en-sa/account",
    meta: indexQWKFaMHH2OMeta || {},
    component: () => import("/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___ar-sa",
    path: "/ar-sa/account",
    meta: indexQWKFaMHH2OMeta || {},
    component: () => import("/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en-qa",
    path: "/en-qa/account",
    meta: indexQWKFaMHH2OMeta || {},
    component: () => import("/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___ar-qa",
    path: "/ar-qa/account",
    meta: indexQWKFaMHH2OMeta || {},
    component: () => import("/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en-kw",
    path: "/en-kw/account",
    meta: indexQWKFaMHH2OMeta || {},
    component: () => import("/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___ar-kw",
    path: "/ar-kw/account",
    meta: indexQWKFaMHH2OMeta || {},
    component: () => import("/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en-om",
    path: "/en-om/account",
    meta: indexQWKFaMHH2OMeta || {},
    component: () => import("/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___ar-om",
    path: "/ar-om/account",
    meta: indexQWKFaMHH2OMeta || {},
    component: () => import("/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en-bh",
    path: "/en-bh/account",
    meta: indexQWKFaMHH2OMeta || {},
    component: () => import("/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___ar-bh",
    path: "/ar-bh/account",
    meta: indexQWKFaMHH2OMeta || {},
    component: () => import("/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-details___en",
    path: "/en/account/orders/details",
    component: () => import("/app/pages/account/orders/details.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-details___ar",
    path: "/ar/account/orders/details",
    component: () => import("/app/pages/account/orders/details.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-details___en-sa",
    path: "/en-sa/account/orders/details",
    component: () => import("/app/pages/account/orders/details.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-details___ar-sa",
    path: "/ar-sa/account/orders/details",
    component: () => import("/app/pages/account/orders/details.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-details___en-qa",
    path: "/en-qa/account/orders/details",
    component: () => import("/app/pages/account/orders/details.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-details___ar-qa",
    path: "/ar-qa/account/orders/details",
    component: () => import("/app/pages/account/orders/details.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-details___en-kw",
    path: "/en-kw/account/orders/details",
    component: () => import("/app/pages/account/orders/details.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-details___ar-kw",
    path: "/ar-kw/account/orders/details",
    component: () => import("/app/pages/account/orders/details.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-details___en-om",
    path: "/en-om/account/orders/details",
    component: () => import("/app/pages/account/orders/details.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-details___ar-om",
    path: "/ar-om/account/orders/details",
    component: () => import("/app/pages/account/orders/details.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-details___en-bh",
    path: "/en-bh/account/orders/details",
    component: () => import("/app/pages/account/orders/details.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-details___ar-bh",
    path: "/ar-bh/account/orders/details",
    component: () => import("/app/pages/account/orders/details.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___en",
    path: "/en/account/orders",
    meta: indexv0iIYE4xFlMeta || {},
    component: () => import("/app/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___ar",
    path: "/ar/account/orders",
    meta: indexv0iIYE4xFlMeta || {},
    component: () => import("/app/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___en-sa",
    path: "/en-sa/account/orders",
    meta: indexv0iIYE4xFlMeta || {},
    component: () => import("/app/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___ar-sa",
    path: "/ar-sa/account/orders",
    meta: indexv0iIYE4xFlMeta || {},
    component: () => import("/app/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___en-qa",
    path: "/en-qa/account/orders",
    meta: indexv0iIYE4xFlMeta || {},
    component: () => import("/app/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___ar-qa",
    path: "/ar-qa/account/orders",
    meta: indexv0iIYE4xFlMeta || {},
    component: () => import("/app/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___en-kw",
    path: "/en-kw/account/orders",
    meta: indexv0iIYE4xFlMeta || {},
    component: () => import("/app/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___ar-kw",
    path: "/ar-kw/account/orders",
    meta: indexv0iIYE4xFlMeta || {},
    component: () => import("/app/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___en-om",
    path: "/en-om/account/orders",
    meta: indexv0iIYE4xFlMeta || {},
    component: () => import("/app/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___ar-om",
    path: "/ar-om/account/orders",
    meta: indexv0iIYE4xFlMeta || {},
    component: () => import("/app/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___en-bh",
    path: "/en-bh/account/orders",
    meta: indexv0iIYE4xFlMeta || {},
    component: () => import("/app/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders___ar-bh",
    path: "/ar-bh/account/orders",
    meta: indexv0iIYE4xFlMeta || {},
    component: () => import("/app/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "account-wishlist___en",
    path: "/en/account/wishlist",
    meta: wishlistzQE62vNi5oMeta || {},
    component: () => import("/app/pages/account/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "account-wishlist___ar",
    path: "/ar/account/wishlist",
    meta: wishlistzQE62vNi5oMeta || {},
    component: () => import("/app/pages/account/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "account-wishlist___en-sa",
    path: "/en-sa/account/wishlist",
    meta: wishlistzQE62vNi5oMeta || {},
    component: () => import("/app/pages/account/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "account-wishlist___ar-sa",
    path: "/ar-sa/account/wishlist",
    meta: wishlistzQE62vNi5oMeta || {},
    component: () => import("/app/pages/account/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "account-wishlist___en-qa",
    path: "/en-qa/account/wishlist",
    meta: wishlistzQE62vNi5oMeta || {},
    component: () => import("/app/pages/account/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "account-wishlist___ar-qa",
    path: "/ar-qa/account/wishlist",
    meta: wishlistzQE62vNi5oMeta || {},
    component: () => import("/app/pages/account/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "account-wishlist___en-kw",
    path: "/en-kw/account/wishlist",
    meta: wishlistzQE62vNi5oMeta || {},
    component: () => import("/app/pages/account/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "account-wishlist___ar-kw",
    path: "/ar-kw/account/wishlist",
    meta: wishlistzQE62vNi5oMeta || {},
    component: () => import("/app/pages/account/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "account-wishlist___en-om",
    path: "/en-om/account/wishlist",
    meta: wishlistzQE62vNi5oMeta || {},
    component: () => import("/app/pages/account/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "account-wishlist___ar-om",
    path: "/ar-om/account/wishlist",
    meta: wishlistzQE62vNi5oMeta || {},
    component: () => import("/app/pages/account/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "account-wishlist___en-bh",
    path: "/en-bh/account/wishlist",
    meta: wishlistzQE62vNi5oMeta || {},
    component: () => import("/app/pages/account/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "account-wishlist___ar-bh",
    path: "/ar-bh/account/wishlist",
    meta: wishlistzQE62vNi5oMeta || {},
    component: () => import("/app/pages/account/wishlist.vue").then(m => m.default || m)
  },
  {
    name: "biogena-diamonds___en",
    path: "/en/biogena-diamonds",
    component: () => import("/app/pages/biogena-diamonds.vue").then(m => m.default || m)
  },
  {
    name: "biogena-diamonds___ar",
    path: "/ar/biogena-diamonds",
    component: () => import("/app/pages/biogena-diamonds.vue").then(m => m.default || m)
  },
  {
    name: "biogena-diamonds___en-sa",
    path: "/en-sa/biogena-diamonds",
    component: () => import("/app/pages/biogena-diamonds.vue").then(m => m.default || m)
  },
  {
    name: "biogena-diamonds___ar-sa",
    path: "/ar-sa/biogena-diamonds",
    component: () => import("/app/pages/biogena-diamonds.vue").then(m => m.default || m)
  },
  {
    name: "biogena-diamonds___en-qa",
    path: "/en-qa/biogena-diamonds",
    component: () => import("/app/pages/biogena-diamonds.vue").then(m => m.default || m)
  },
  {
    name: "biogena-diamonds___ar-qa",
    path: "/ar-qa/biogena-diamonds",
    component: () => import("/app/pages/biogena-diamonds.vue").then(m => m.default || m)
  },
  {
    name: "biogena-diamonds___en-kw",
    path: "/en-kw/biogena-diamonds",
    component: () => import("/app/pages/biogena-diamonds.vue").then(m => m.default || m)
  },
  {
    name: "biogena-diamonds___ar-kw",
    path: "/ar-kw/biogena-diamonds",
    component: () => import("/app/pages/biogena-diamonds.vue").then(m => m.default || m)
  },
  {
    name: "biogena-diamonds___en-om",
    path: "/en-om/biogena-diamonds",
    component: () => import("/app/pages/biogena-diamonds.vue").then(m => m.default || m)
  },
  {
    name: "biogena-diamonds___ar-om",
    path: "/ar-om/biogena-diamonds",
    component: () => import("/app/pages/biogena-diamonds.vue").then(m => m.default || m)
  },
  {
    name: "biogena-diamonds___en-bh",
    path: "/en-bh/biogena-diamonds",
    component: () => import("/app/pages/biogena-diamonds.vue").then(m => m.default || m)
  },
  {
    name: "biogena-diamonds___ar-bh",
    path: "/ar-bh/biogena-diamonds",
    component: () => import("/app/pages/biogena-diamonds.vue").then(m => m.default || m)
  },
  {
    name: "blog-blog___en",
    path: "/en/blog/:blog()",
    component: () => import("/app/pages/blog/[blog].vue").then(m => m.default || m)
  },
  {
    name: "blog-blog___ar",
    path: "/ar/blog/:blog()",
    component: () => import("/app/pages/blog/[blog].vue").then(m => m.default || m)
  },
  {
    name: "blog-blog___en-sa",
    path: "/en-sa/blog/:blog()",
    component: () => import("/app/pages/blog/[blog].vue").then(m => m.default || m)
  },
  {
    name: "blog-blog___ar-sa",
    path: "/ar-sa/blog/:blog()",
    component: () => import("/app/pages/blog/[blog].vue").then(m => m.default || m)
  },
  {
    name: "blog-blog___en-qa",
    path: "/en-qa/blog/:blog()",
    component: () => import("/app/pages/blog/[blog].vue").then(m => m.default || m)
  },
  {
    name: "blog-blog___ar-qa",
    path: "/ar-qa/blog/:blog()",
    component: () => import("/app/pages/blog/[blog].vue").then(m => m.default || m)
  },
  {
    name: "blog-blog___en-kw",
    path: "/en-kw/blog/:blog()",
    component: () => import("/app/pages/blog/[blog].vue").then(m => m.default || m)
  },
  {
    name: "blog-blog___ar-kw",
    path: "/ar-kw/blog/:blog()",
    component: () => import("/app/pages/blog/[blog].vue").then(m => m.default || m)
  },
  {
    name: "blog-blog___en-om",
    path: "/en-om/blog/:blog()",
    component: () => import("/app/pages/blog/[blog].vue").then(m => m.default || m)
  },
  {
    name: "blog-blog___ar-om",
    path: "/ar-om/blog/:blog()",
    component: () => import("/app/pages/blog/[blog].vue").then(m => m.default || m)
  },
  {
    name: "blog-blog___en-bh",
    path: "/en-bh/blog/:blog()",
    component: () => import("/app/pages/blog/[blog].vue").then(m => m.default || m)
  },
  {
    name: "blog-blog___ar-bh",
    path: "/ar-bh/blog/:blog()",
    component: () => import("/app/pages/blog/[blog].vue").then(m => m.default || m)
  },
  {
    name: "blog___en",
    path: "/en/blog",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___ar",
    path: "/ar/blog",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___en-sa",
    path: "/en-sa/blog",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___ar-sa",
    path: "/ar-sa/blog",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___en-qa",
    path: "/en-qa/blog",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___ar-qa",
    path: "/ar-qa/blog",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___en-kw",
    path: "/en-kw/blog",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___ar-kw",
    path: "/ar-kw/blog",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___en-om",
    path: "/en-om/blog",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___ar-om",
    path: "/ar-om/blog",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___en-bh",
    path: "/en-bh/blog",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "blog___ar-bh",
    path: "/ar-bh/blog",
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___en",
    path: "/en/checkout",
    meta: index7ZtduOIoVXMeta || {},
    component: () => import("/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___ar",
    path: "/ar/checkout",
    meta: index7ZtduOIoVXMeta || {},
    component: () => import("/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___en-sa",
    path: "/en-sa/checkout",
    meta: index7ZtduOIoVXMeta || {},
    component: () => import("/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___ar-sa",
    path: "/ar-sa/checkout",
    meta: index7ZtduOIoVXMeta || {},
    component: () => import("/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___en-qa",
    path: "/en-qa/checkout",
    meta: index7ZtduOIoVXMeta || {},
    component: () => import("/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___ar-qa",
    path: "/ar-qa/checkout",
    meta: index7ZtduOIoVXMeta || {},
    component: () => import("/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___en-kw",
    path: "/en-kw/checkout",
    meta: index7ZtduOIoVXMeta || {},
    component: () => import("/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___ar-kw",
    path: "/ar-kw/checkout",
    meta: index7ZtduOIoVXMeta || {},
    component: () => import("/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___en-om",
    path: "/en-om/checkout",
    meta: index7ZtduOIoVXMeta || {},
    component: () => import("/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___ar-om",
    path: "/ar-om/checkout",
    meta: index7ZtduOIoVXMeta || {},
    component: () => import("/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___en-bh",
    path: "/en-bh/checkout",
    meta: index7ZtduOIoVXMeta || {},
    component: () => import("/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout___ar-bh",
    path: "/ar-bh/checkout",
    meta: index7ZtduOIoVXMeta || {},
    component: () => import("/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-order-confirmation___en",
    path: "/en/checkout/order-confirmation",
    component: () => import("/app/pages/checkout/order-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "checkout-order-confirmation___ar",
    path: "/ar/checkout/order-confirmation",
    component: () => import("/app/pages/checkout/order-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "checkout-order-confirmation___en-sa",
    path: "/en-sa/checkout/order-confirmation",
    component: () => import("/app/pages/checkout/order-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "checkout-order-confirmation___ar-sa",
    path: "/ar-sa/checkout/order-confirmation",
    component: () => import("/app/pages/checkout/order-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "checkout-order-confirmation___en-qa",
    path: "/en-qa/checkout/order-confirmation",
    component: () => import("/app/pages/checkout/order-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "checkout-order-confirmation___ar-qa",
    path: "/ar-qa/checkout/order-confirmation",
    component: () => import("/app/pages/checkout/order-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "checkout-order-confirmation___en-kw",
    path: "/en-kw/checkout/order-confirmation",
    component: () => import("/app/pages/checkout/order-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "checkout-order-confirmation___ar-kw",
    path: "/ar-kw/checkout/order-confirmation",
    component: () => import("/app/pages/checkout/order-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "checkout-order-confirmation___en-om",
    path: "/en-om/checkout/order-confirmation",
    component: () => import("/app/pages/checkout/order-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "checkout-order-confirmation___ar-om",
    path: "/ar-om/checkout/order-confirmation",
    component: () => import("/app/pages/checkout/order-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "checkout-order-confirmation___en-bh",
    path: "/en-bh/checkout/order-confirmation",
    component: () => import("/app/pages/checkout/order-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "checkout-order-confirmation___ar-bh",
    path: "/ar-bh/checkout/order-confirmation",
    component: () => import("/app/pages/checkout/order-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___ar",
    path: "/ar/contact",
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___en-sa",
    path: "/en-sa/contact",
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___ar-sa",
    path: "/ar-sa/contact",
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___en-qa",
    path: "/en-qa/contact",
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___ar-qa",
    path: "/ar-qa/contact",
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___en-kw",
    path: "/en-kw/contact",
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___ar-kw",
    path: "/ar-kw/contact",
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___en-om",
    path: "/en-om/contact",
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___ar-om",
    path: "/ar-om/contact",
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___en-bh",
    path: "/en-bh/contact",
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "contact___ar-bh",
    path: "/ar-bh/contact",
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "faq___en",
    path: "/en/faq",
    component: () => import("/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___ar",
    path: "/ar/faq",
    component: () => import("/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___en-sa",
    path: "/en-sa/faq",
    component: () => import("/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___ar-sa",
    path: "/ar-sa/faq",
    component: () => import("/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___en-qa",
    path: "/en-qa/faq",
    component: () => import("/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___ar-qa",
    path: "/ar-qa/faq",
    component: () => import("/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___en-kw",
    path: "/en-kw/faq",
    component: () => import("/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___ar-kw",
    path: "/ar-kw/faq",
    component: () => import("/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___en-om",
    path: "/en-om/faq",
    component: () => import("/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___ar-om",
    path: "/ar-om/faq",
    component: () => import("/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___en-bh",
    path: "/en-bh/faq",
    component: () => import("/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "faq___ar-bh",
    path: "/ar-bh/faq",
    component: () => import("/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___en",
    path: "/en/forgot-password",
    component: () => import("/app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___ar",
    path: "/ar/forgot-password",
    component: () => import("/app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___en-sa",
    path: "/en-sa/forgot-password",
    component: () => import("/app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___ar-sa",
    path: "/ar-sa/forgot-password",
    component: () => import("/app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___en-qa",
    path: "/en-qa/forgot-password",
    component: () => import("/app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___ar-qa",
    path: "/ar-qa/forgot-password",
    component: () => import("/app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___en-kw",
    path: "/en-kw/forgot-password",
    component: () => import("/app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___ar-kw",
    path: "/ar-kw/forgot-password",
    component: () => import("/app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___en-om",
    path: "/en-om/forgot-password",
    component: () => import("/app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___ar-om",
    path: "/ar-om/forgot-password",
    component: () => import("/app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___en-bh",
    path: "/en-bh/forgot-password",
    component: () => import("/app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___ar-bh",
    path: "/ar-bh/forgot-password",
    component: () => import("/app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ar",
    path: "/ar",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en-sa",
    path: "/en-sa",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ar-sa",
    path: "/ar-sa",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en-qa",
    path: "/en-qa",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ar-qa",
    path: "/ar-qa",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en-kw",
    path: "/en-kw",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ar-kw",
    path: "/ar-kw",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en-om",
    path: "/en-om",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ar-om",
    path: "/ar-om",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en-bh",
    path: "/en-bh",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___ar-bh",
    path: "/ar-bh",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: indexgK5VicK7y2Meta || {},
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login___ar",
    path: "/ar/login",
    meta: indexgK5VicK7y2Meta || {},
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login___en-sa",
    path: "/en-sa/login",
    meta: indexgK5VicK7y2Meta || {},
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login___ar-sa",
    path: "/ar-sa/login",
    meta: indexgK5VicK7y2Meta || {},
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login___en-qa",
    path: "/en-qa/login",
    meta: indexgK5VicK7y2Meta || {},
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login___ar-qa",
    path: "/ar-qa/login",
    meta: indexgK5VicK7y2Meta || {},
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login___en-kw",
    path: "/en-kw/login",
    meta: indexgK5VicK7y2Meta || {},
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login___ar-kw",
    path: "/ar-kw/login",
    meta: indexgK5VicK7y2Meta || {},
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login___en-om",
    path: "/en-om/login",
    meta: indexgK5VicK7y2Meta || {},
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login___ar-om",
    path: "/ar-om/login",
    meta: indexgK5VicK7y2Meta || {},
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login___en-bh",
    path: "/en-bh/login",
    meta: indexgK5VicK7y2Meta || {},
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login___ar-bh",
    path: "/ar-bh/login",
    meta: indexgK5VicK7y2Meta || {},
    component: () => import("/app/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login-magic___en",
    path: "/en/login/magic",
    component: () => import("/app/pages/login/magic.vue").then(m => m.default || m)
  },
  {
    name: "login-magic___ar",
    path: "/ar/login/magic",
    component: () => import("/app/pages/login/magic.vue").then(m => m.default || m)
  },
  {
    name: "login-magic___en-sa",
    path: "/en-sa/login/magic",
    component: () => import("/app/pages/login/magic.vue").then(m => m.default || m)
  },
  {
    name: "login-magic___ar-sa",
    path: "/ar-sa/login/magic",
    component: () => import("/app/pages/login/magic.vue").then(m => m.default || m)
  },
  {
    name: "login-magic___en-qa",
    path: "/en-qa/login/magic",
    component: () => import("/app/pages/login/magic.vue").then(m => m.default || m)
  },
  {
    name: "login-magic___ar-qa",
    path: "/ar-qa/login/magic",
    component: () => import("/app/pages/login/magic.vue").then(m => m.default || m)
  },
  {
    name: "login-magic___en-kw",
    path: "/en-kw/login/magic",
    component: () => import("/app/pages/login/magic.vue").then(m => m.default || m)
  },
  {
    name: "login-magic___ar-kw",
    path: "/ar-kw/login/magic",
    component: () => import("/app/pages/login/magic.vue").then(m => m.default || m)
  },
  {
    name: "login-magic___en-om",
    path: "/en-om/login/magic",
    component: () => import("/app/pages/login/magic.vue").then(m => m.default || m)
  },
  {
    name: "login-magic___ar-om",
    path: "/ar-om/login/magic",
    component: () => import("/app/pages/login/magic.vue").then(m => m.default || m)
  },
  {
    name: "login-magic___en-bh",
    path: "/en-bh/login/magic",
    component: () => import("/app/pages/login/magic.vue").then(m => m.default || m)
  },
  {
    name: "login-magic___ar-bh",
    path: "/ar-bh/login/magic",
    component: () => import("/app/pages/login/magic.vue").then(m => m.default || m)
  },
  {
    name: "login-oauth___en",
    path: "/en/login/oauth",
    component: () => import("/app/pages/login/oauth.vue").then(m => m.default || m)
  },
  {
    name: "login-oauth___ar",
    path: "/ar/login/oauth",
    component: () => import("/app/pages/login/oauth.vue").then(m => m.default || m)
  },
  {
    name: "login-oauth___en-sa",
    path: "/en-sa/login/oauth",
    component: () => import("/app/pages/login/oauth.vue").then(m => m.default || m)
  },
  {
    name: "login-oauth___ar-sa",
    path: "/ar-sa/login/oauth",
    component: () => import("/app/pages/login/oauth.vue").then(m => m.default || m)
  },
  {
    name: "login-oauth___en-qa",
    path: "/en-qa/login/oauth",
    component: () => import("/app/pages/login/oauth.vue").then(m => m.default || m)
  },
  {
    name: "login-oauth___ar-qa",
    path: "/ar-qa/login/oauth",
    component: () => import("/app/pages/login/oauth.vue").then(m => m.default || m)
  },
  {
    name: "login-oauth___en-kw",
    path: "/en-kw/login/oauth",
    component: () => import("/app/pages/login/oauth.vue").then(m => m.default || m)
  },
  {
    name: "login-oauth___ar-kw",
    path: "/ar-kw/login/oauth",
    component: () => import("/app/pages/login/oauth.vue").then(m => m.default || m)
  },
  {
    name: "login-oauth___en-om",
    path: "/en-om/login/oauth",
    component: () => import("/app/pages/login/oauth.vue").then(m => m.default || m)
  },
  {
    name: "login-oauth___ar-om",
    path: "/ar-om/login/oauth",
    component: () => import("/app/pages/login/oauth.vue").then(m => m.default || m)
  },
  {
    name: "login-oauth___en-bh",
    path: "/en-bh/login/oauth",
    component: () => import("/app/pages/login/oauth.vue").then(m => m.default || m)
  },
  {
    name: "login-oauth___ar-bh",
    path: "/ar-bh/login/oauth",
    component: () => import("/app/pages/login/oauth.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en",
    path: "/en/privacy-policy",
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___ar",
    path: "/ar/privacy-policy",
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en-sa",
    path: "/en-sa/privacy-policy",
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___ar-sa",
    path: "/ar-sa/privacy-policy",
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en-qa",
    path: "/en-qa/privacy-policy",
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___ar-qa",
    path: "/ar-qa/privacy-policy",
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en-kw",
    path: "/en-kw/privacy-policy",
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___ar-kw",
    path: "/ar-kw/privacy-policy",
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en-om",
    path: "/en-om/privacy-policy",
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___ar-om",
    path: "/ar-om/privacy-policy",
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___en-bh",
    path: "/en-bh/privacy-policy",
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy___ar-bh",
    path: "/ar-bh/privacy-policy",
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "product-product___en",
    path: "/en/product/:product()",
    component: () => import("/app/pages/product/[product].vue").then(m => m.default || m)
  },
  {
    name: "product-product___ar",
    path: "/ar/product/:product()",
    component: () => import("/app/pages/product/[product].vue").then(m => m.default || m)
  },
  {
    name: "product-product___en-sa",
    path: "/en-sa/product/:product()",
    component: () => import("/app/pages/product/[product].vue").then(m => m.default || m)
  },
  {
    name: "product-product___ar-sa",
    path: "/ar-sa/product/:product()",
    component: () => import("/app/pages/product/[product].vue").then(m => m.default || m)
  },
  {
    name: "product-product___en-qa",
    path: "/en-qa/product/:product()",
    component: () => import("/app/pages/product/[product].vue").then(m => m.default || m)
  },
  {
    name: "product-product___ar-qa",
    path: "/ar-qa/product/:product()",
    component: () => import("/app/pages/product/[product].vue").then(m => m.default || m)
  },
  {
    name: "product-product___en-kw",
    path: "/en-kw/product/:product()",
    component: () => import("/app/pages/product/[product].vue").then(m => m.default || m)
  },
  {
    name: "product-product___ar-kw",
    path: "/ar-kw/product/:product()",
    component: () => import("/app/pages/product/[product].vue").then(m => m.default || m)
  },
  {
    name: "product-product___en-om",
    path: "/en-om/product/:product()",
    component: () => import("/app/pages/product/[product].vue").then(m => m.default || m)
  },
  {
    name: "product-product___ar-om",
    path: "/ar-om/product/:product()",
    component: () => import("/app/pages/product/[product].vue").then(m => m.default || m)
  },
  {
    name: "product-product___en-bh",
    path: "/en-bh/product/:product()",
    component: () => import("/app/pages/product/[product].vue").then(m => m.default || m)
  },
  {
    name: "product-product___ar-bh",
    path: "/ar-bh/product/:product()",
    component: () => import("/app/pages/product/[product].vue").then(m => m.default || m)
  },
  {
    name: "register___en",
    path: "/en/register",
    component: () => import("/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___ar",
    path: "/ar/register",
    component: () => import("/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___en-sa",
    path: "/en-sa/register",
    component: () => import("/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___ar-sa",
    path: "/ar-sa/register",
    component: () => import("/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___en-qa",
    path: "/en-qa/register",
    component: () => import("/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___ar-qa",
    path: "/ar-qa/register",
    component: () => import("/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___en-kw",
    path: "/en-kw/register",
    component: () => import("/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___ar-kw",
    path: "/ar-kw/register",
    component: () => import("/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___en-om",
    path: "/en-om/register",
    component: () => import("/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___ar-om",
    path: "/ar-om/register",
    component: () => import("/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___en-bh",
    path: "/en-bh/register",
    component: () => import("/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "register___ar-bh",
    path: "/ar-bh/register",
    component: () => import("/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "reset-password___en",
    path: "/en/reset-password",
    meta: reset_45password52TOS9kgCUMeta || {},
    component: () => import("/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "reset-password___ar",
    path: "/ar/reset-password",
    meta: reset_45password52TOS9kgCUMeta || {},
    component: () => import("/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "reset-password___en-sa",
    path: "/en-sa/reset-password",
    meta: reset_45password52TOS9kgCUMeta || {},
    component: () => import("/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "reset-password___ar-sa",
    path: "/ar-sa/reset-password",
    meta: reset_45password52TOS9kgCUMeta || {},
    component: () => import("/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "reset-password___en-qa",
    path: "/en-qa/reset-password",
    meta: reset_45password52TOS9kgCUMeta || {},
    component: () => import("/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "reset-password___ar-qa",
    path: "/ar-qa/reset-password",
    meta: reset_45password52TOS9kgCUMeta || {},
    component: () => import("/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "reset-password___en-kw",
    path: "/en-kw/reset-password",
    meta: reset_45password52TOS9kgCUMeta || {},
    component: () => import("/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "reset-password___ar-kw",
    path: "/ar-kw/reset-password",
    meta: reset_45password52TOS9kgCUMeta || {},
    component: () => import("/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "reset-password___en-om",
    path: "/en-om/reset-password",
    meta: reset_45password52TOS9kgCUMeta || {},
    component: () => import("/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "reset-password___ar-om",
    path: "/ar-om/reset-password",
    meta: reset_45password52TOS9kgCUMeta || {},
    component: () => import("/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "reset-password___en-bh",
    path: "/en-bh/reset-password",
    meta: reset_45password52TOS9kgCUMeta || {},
    component: () => import("/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "reset-password___ar-bh",
    path: "/ar-bh/reset-password",
    meta: reset_45password52TOS9kgCUMeta || {},
    component: () => import("/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "return-policy___en",
    path: "/en/return-policy",
    component: () => import("/app/pages/return-policy.vue").then(m => m.default || m)
  },
  {
    name: "return-policy___ar",
    path: "/ar/return-policy",
    component: () => import("/app/pages/return-policy.vue").then(m => m.default || m)
  },
  {
    name: "return-policy___en-sa",
    path: "/en-sa/return-policy",
    component: () => import("/app/pages/return-policy.vue").then(m => m.default || m)
  },
  {
    name: "return-policy___ar-sa",
    path: "/ar-sa/return-policy",
    component: () => import("/app/pages/return-policy.vue").then(m => m.default || m)
  },
  {
    name: "return-policy___en-qa",
    path: "/en-qa/return-policy",
    component: () => import("/app/pages/return-policy.vue").then(m => m.default || m)
  },
  {
    name: "return-policy___ar-qa",
    path: "/ar-qa/return-policy",
    component: () => import("/app/pages/return-policy.vue").then(m => m.default || m)
  },
  {
    name: "return-policy___en-kw",
    path: "/en-kw/return-policy",
    component: () => import("/app/pages/return-policy.vue").then(m => m.default || m)
  },
  {
    name: "return-policy___ar-kw",
    path: "/ar-kw/return-policy",
    component: () => import("/app/pages/return-policy.vue").then(m => m.default || m)
  },
  {
    name: "return-policy___en-om",
    path: "/en-om/return-policy",
    component: () => import("/app/pages/return-policy.vue").then(m => m.default || m)
  },
  {
    name: "return-policy___ar-om",
    path: "/ar-om/return-policy",
    component: () => import("/app/pages/return-policy.vue").then(m => m.default || m)
  },
  {
    name: "return-policy___en-bh",
    path: "/en-bh/return-policy",
    component: () => import("/app/pages/return-policy.vue").then(m => m.default || m)
  },
  {
    name: "return-policy___ar-bh",
    path: "/ar-bh/return-policy",
    component: () => import("/app/pages/return-policy.vue").then(m => m.default || m)
  },
  {
    name: "shop___en",
    path: "/en/shop",
    component: () => import("/app/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "shop___ar",
    path: "/ar/shop",
    component: () => import("/app/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "shop___en-sa",
    path: "/en-sa/shop",
    component: () => import("/app/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "shop___ar-sa",
    path: "/ar-sa/shop",
    component: () => import("/app/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "shop___en-qa",
    path: "/en-qa/shop",
    component: () => import("/app/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "shop___ar-qa",
    path: "/ar-qa/shop",
    component: () => import("/app/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "shop___en-kw",
    path: "/en-kw/shop",
    component: () => import("/app/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "shop___ar-kw",
    path: "/ar-kw/shop",
    component: () => import("/app/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "shop___en-om",
    path: "/en-om/shop",
    component: () => import("/app/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "shop___ar-om",
    path: "/ar-om/shop",
    component: () => import("/app/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "shop___en-bh",
    path: "/en-bh/shop",
    component: () => import("/app/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "shop___ar-bh",
    path: "/ar-bh/shop",
    component: () => import("/app/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: "stories___en",
    path: "/en/stories",
    component: () => import("/app/pages/stories.vue").then(m => m.default || m)
  },
  {
    name: "stories___ar",
    path: "/ar/stories",
    component: () => import("/app/pages/stories.vue").then(m => m.default || m)
  },
  {
    name: "stories___en-sa",
    path: "/en-sa/stories",
    component: () => import("/app/pages/stories.vue").then(m => m.default || m)
  },
  {
    name: "stories___ar-sa",
    path: "/ar-sa/stories",
    component: () => import("/app/pages/stories.vue").then(m => m.default || m)
  },
  {
    name: "stories___en-qa",
    path: "/en-qa/stories",
    component: () => import("/app/pages/stories.vue").then(m => m.default || m)
  },
  {
    name: "stories___ar-qa",
    path: "/ar-qa/stories",
    component: () => import("/app/pages/stories.vue").then(m => m.default || m)
  },
  {
    name: "stories___en-kw",
    path: "/en-kw/stories",
    component: () => import("/app/pages/stories.vue").then(m => m.default || m)
  },
  {
    name: "stories___ar-kw",
    path: "/ar-kw/stories",
    component: () => import("/app/pages/stories.vue").then(m => m.default || m)
  },
  {
    name: "stories___en-om",
    path: "/en-om/stories",
    component: () => import("/app/pages/stories.vue").then(m => m.default || m)
  },
  {
    name: "stories___ar-om",
    path: "/ar-om/stories",
    component: () => import("/app/pages/stories.vue").then(m => m.default || m)
  },
  {
    name: "stories___en-bh",
    path: "/en-bh/stories",
    component: () => import("/app/pages/stories.vue").then(m => m.default || m)
  },
  {
    name: "stories___ar-bh",
    path: "/ar-bh/stories",
    component: () => import("/app/pages/stories.vue").then(m => m.default || m)
  },
  {
    name: "story-story___en",
    path: "/en/story/:story()",
    component: () => import("/app/pages/story/[story].vue").then(m => m.default || m)
  },
  {
    name: "story-story___ar",
    path: "/ar/story/:story()",
    component: () => import("/app/pages/story/[story].vue").then(m => m.default || m)
  },
  {
    name: "story-story___en-sa",
    path: "/en-sa/story/:story()",
    component: () => import("/app/pages/story/[story].vue").then(m => m.default || m)
  },
  {
    name: "story-story___ar-sa",
    path: "/ar-sa/story/:story()",
    component: () => import("/app/pages/story/[story].vue").then(m => m.default || m)
  },
  {
    name: "story-story___en-qa",
    path: "/en-qa/story/:story()",
    component: () => import("/app/pages/story/[story].vue").then(m => m.default || m)
  },
  {
    name: "story-story___ar-qa",
    path: "/ar-qa/story/:story()",
    component: () => import("/app/pages/story/[story].vue").then(m => m.default || m)
  },
  {
    name: "story-story___en-kw",
    path: "/en-kw/story/:story()",
    component: () => import("/app/pages/story/[story].vue").then(m => m.default || m)
  },
  {
    name: "story-story___ar-kw",
    path: "/ar-kw/story/:story()",
    component: () => import("/app/pages/story/[story].vue").then(m => m.default || m)
  },
  {
    name: "story-story___en-om",
    path: "/en-om/story/:story()",
    component: () => import("/app/pages/story/[story].vue").then(m => m.default || m)
  },
  {
    name: "story-story___ar-om",
    path: "/ar-om/story/:story()",
    component: () => import("/app/pages/story/[story].vue").then(m => m.default || m)
  },
  {
    name: "story-story___en-bh",
    path: "/en-bh/story/:story()",
    component: () => import("/app/pages/story/[story].vue").then(m => m.default || m)
  },
  {
    name: "story-story___ar-bh",
    path: "/ar-bh/story/:story()",
    component: () => import("/app/pages/story/[story].vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___en",
    path: "/en/terms-and-conditions",
    component: () => import("/app/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___ar",
    path: "/ar/terms-and-conditions",
    component: () => import("/app/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___en-sa",
    path: "/en-sa/terms-and-conditions",
    component: () => import("/app/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___ar-sa",
    path: "/ar-sa/terms-and-conditions",
    component: () => import("/app/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___en-qa",
    path: "/en-qa/terms-and-conditions",
    component: () => import("/app/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___ar-qa",
    path: "/ar-qa/terms-and-conditions",
    component: () => import("/app/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___en-kw",
    path: "/en-kw/terms-and-conditions",
    component: () => import("/app/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___ar-kw",
    path: "/ar-kw/terms-and-conditions",
    component: () => import("/app/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___en-om",
    path: "/en-om/terms-and-conditions",
    component: () => import("/app/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___ar-om",
    path: "/ar-om/terms-and-conditions",
    component: () => import("/app/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___en-bh",
    path: "/en-bh/terms-and-conditions",
    component: () => import("/app/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions___ar-bh",
    path: "/ar-bh/terms-and-conditions",
    component: () => import("/app/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "track-order___en",
    path: "/en/track-order",
    component: () => import("/app/pages/track-order.vue").then(m => m.default || m)
  },
  {
    name: "track-order___ar",
    path: "/ar/track-order",
    component: () => import("/app/pages/track-order.vue").then(m => m.default || m)
  },
  {
    name: "track-order___en-sa",
    path: "/en-sa/track-order",
    component: () => import("/app/pages/track-order.vue").then(m => m.default || m)
  },
  {
    name: "track-order___ar-sa",
    path: "/ar-sa/track-order",
    component: () => import("/app/pages/track-order.vue").then(m => m.default || m)
  },
  {
    name: "track-order___en-qa",
    path: "/en-qa/track-order",
    component: () => import("/app/pages/track-order.vue").then(m => m.default || m)
  },
  {
    name: "track-order___ar-qa",
    path: "/ar-qa/track-order",
    component: () => import("/app/pages/track-order.vue").then(m => m.default || m)
  },
  {
    name: "track-order___en-kw",
    path: "/en-kw/track-order",
    component: () => import("/app/pages/track-order.vue").then(m => m.default || m)
  },
  {
    name: "track-order___ar-kw",
    path: "/ar-kw/track-order",
    component: () => import("/app/pages/track-order.vue").then(m => m.default || m)
  },
  {
    name: "track-order___en-om",
    path: "/en-om/track-order",
    component: () => import("/app/pages/track-order.vue").then(m => m.default || m)
  },
  {
    name: "track-order___ar-om",
    path: "/ar-om/track-order",
    component: () => import("/app/pages/track-order.vue").then(m => m.default || m)
  },
  {
    name: "track-order___en-bh",
    path: "/en-bh/track-order",
    component: () => import("/app/pages/track-order.vue").then(m => m.default || m)
  },
  {
    name: "track-order___ar-bh",
    path: "/ar-bh/track-order",
    component: () => import("/app/pages/track-order.vue").then(m => m.default || m)
  },
  {
    name: "warnings___en",
    path: "/en/warnings",
    component: () => import("/app/pages/warnings.vue").then(m => m.default || m)
  },
  {
    name: "warnings___ar",
    path: "/ar/warnings",
    component: () => import("/app/pages/warnings.vue").then(m => m.default || m)
  },
  {
    name: "warnings___en-sa",
    path: "/en-sa/warnings",
    component: () => import("/app/pages/warnings.vue").then(m => m.default || m)
  },
  {
    name: "warnings___ar-sa",
    path: "/ar-sa/warnings",
    component: () => import("/app/pages/warnings.vue").then(m => m.default || m)
  },
  {
    name: "warnings___en-qa",
    path: "/en-qa/warnings",
    component: () => import("/app/pages/warnings.vue").then(m => m.default || m)
  },
  {
    name: "warnings___ar-qa",
    path: "/ar-qa/warnings",
    component: () => import("/app/pages/warnings.vue").then(m => m.default || m)
  },
  {
    name: "warnings___en-kw",
    path: "/en-kw/warnings",
    component: () => import("/app/pages/warnings.vue").then(m => m.default || m)
  },
  {
    name: "warnings___ar-kw",
    path: "/ar-kw/warnings",
    component: () => import("/app/pages/warnings.vue").then(m => m.default || m)
  },
  {
    name: "warnings___en-om",
    path: "/en-om/warnings",
    component: () => import("/app/pages/warnings.vue").then(m => m.default || m)
  },
  {
    name: "warnings___ar-om",
    path: "/ar-om/warnings",
    component: () => import("/app/pages/warnings.vue").then(m => m.default || m)
  },
  {
    name: "warnings___en-bh",
    path: "/en-bh/warnings",
    component: () => import("/app/pages/warnings.vue").then(m => m.default || m)
  },
  {
    name: "warnings___ar-bh",
    path: "/ar-bh/warnings",
    component: () => import("/app/pages/warnings.vue").then(m => m.default || m)
  },
  {
    name: "why-biogena___en",
    path: "/en/why-biogena",
    component: () => import("/app/pages/why-biogena.vue").then(m => m.default || m)
  },
  {
    name: "why-biogena___ar",
    path: "/ar/why-biogena",
    component: () => import("/app/pages/why-biogena.vue").then(m => m.default || m)
  },
  {
    name: "why-biogena___en-sa",
    path: "/en-sa/why-biogena",
    component: () => import("/app/pages/why-biogena.vue").then(m => m.default || m)
  },
  {
    name: "why-biogena___ar-sa",
    path: "/ar-sa/why-biogena",
    component: () => import("/app/pages/why-biogena.vue").then(m => m.default || m)
  },
  {
    name: "why-biogena___en-qa",
    path: "/en-qa/why-biogena",
    component: () => import("/app/pages/why-biogena.vue").then(m => m.default || m)
  },
  {
    name: "why-biogena___ar-qa",
    path: "/ar-qa/why-biogena",
    component: () => import("/app/pages/why-biogena.vue").then(m => m.default || m)
  },
  {
    name: "why-biogena___en-kw",
    path: "/en-kw/why-biogena",
    component: () => import("/app/pages/why-biogena.vue").then(m => m.default || m)
  },
  {
    name: "why-biogena___ar-kw",
    path: "/ar-kw/why-biogena",
    component: () => import("/app/pages/why-biogena.vue").then(m => m.default || m)
  },
  {
    name: "why-biogena___en-om",
    path: "/en-om/why-biogena",
    component: () => import("/app/pages/why-biogena.vue").then(m => m.default || m)
  },
  {
    name: "why-biogena___ar-om",
    path: "/ar-om/why-biogena",
    component: () => import("/app/pages/why-biogena.vue").then(m => m.default || m)
  },
  {
    name: "why-biogena___en-bh",
    path: "/en-bh/why-biogena",
    component: () => import("/app/pages/why-biogena.vue").then(m => m.default || m)
  },
  {
    name: "why-biogena___ar-bh",
    path: "/ar-bh/why-biogena",
    component: () => import("/app/pages/why-biogena.vue").then(m => m.default || m)
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubnO9MU04yTU
  }
]