<template>
  <div>
    <button @click="showMenu = true">
      <Icon name="pepicons-print:menu" class="text-primary-1000 rtl:-scale-[1]" size="24" />
    </button>
    <Transition name="fade">
      <div v-show="showMenu" class="fixed top-0 bottom-[73px] left-0 w-full z-50 bg-black/50 backdrop-blur-md">
        <Transition name="slide">
          <nav
            v-show="showMenu"
            ref="mobileMenu"
            class="bg-white max-w-[344px] flex flex-col h-full w-full shadow-md px-8 py-6"
          >
            <div class="flex justify-between items-center">
              <button @click="showMenu = false">
                <Icon name="ph:x-thin" />
              </button>
              <NuxtLink :to="generateLink('/')">
                <img src="~/assets/images/biogena_logo.svg" width="120" />
              </NuxtLink>
              <HeadlessMenu as="div" class="flex relative">
                <NuxtLink
                  @click="showMenu = false"
                  :to="locale === 'en' ? switchLocale('ar') : switchLocale('en')"
                  class="flex relative border-primary-300 ltr:lg:border-r ltr:pr-4 rtl:lg:border-l"
                >
                  <div class="flex items-center font-bold text-primary-1000">
                    {{ locale == 'ar' ? 'English' : 'عربي' }}
                  </div>
                </NuxtLink>

                <transition
                  enter-active-class="transition duration-100 ease-out"
                  enter-from-class="transform scale-95 opacity-0"
                  enter-to-class="transform scale-100 opacity-100"
                  leave-active-class="transition duration-75 ease-in"
                  leave-from-class="transform scale-100 opacity-100"
                  leave-to-class="transform scale-95 opacity-0"
                >
                  <HeadlessMenuItems
                    class="absolute right-0 mt-8 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
                  >
                    <div class="p-3">
                      <HeadlessMenuItem v-for="locale in availableLocales" :key="locale.code" v-slot="{ close }">
                        <NuxtLink
                          :to="switchLocale(locale.code)"
                          class="group flex w-full items-center rounded-md text-sm"
                          @click="close"
                        >
                          <div class="flex items-center" @click="close">
                            {{ locale.name }}
                          </div>
                        </NuxtLink>
                      </HeadlessMenuItem>
                    </div>
                  </HeadlessMenuItems>
                </transition>
              </HeadlessMenu>
            </div>
            <ul class="my-8 flex flex-col overflow-auto max-h-[75vh]">
              <li v-for="item in menuItems" :key="item.id" class="cursor-pointer">
                <Transition name="fade-nav">
                  <button
                    v-if="!selectedCategory || selectedCategory === item.id"
                    class="flex items-center gap-5 mb-6"
                    :class="selectedCategory && 'flex-row-reverse justify-end'"
                    @click="item.subItems && item.subItems.length > 0 ? onSelectCategory(item.id) : undefined"
                  >
                    <NuxtLink v-if="item.isClickable" :to="generateLink(item.slug)" @click="showMenu = false">
                      <span
                        class="text-sm"
                        :class="selectedCategory ? 'text-xl font-bold text-primary-1000' : 'text-xl font-medium'"
                      >
                        {{ t(item.title) }}
                      </span>
                    </NuxtLink>
                    <span
                      v-else
                      class="text-sm"
                      :class="selectedCategory ? 'text-xl font-bold text-primary-1000' : 'text-xl font-medium'"
                    >
                      {{ t(item.title) }}
                    </span>

                    <Icon
                      v-if="item.subItems && item.subItems.length > 0"
                      name="ic:round-chevron-right"
                      :class="[selectedCategory && 'rotate-180'].join(' ')"
                      class="rtl:rotate-180"
                    />
                  </button>
                </Transition>

                <ul
                  v-if="item.subItems && item.subItems.length > 0"
                  :class="
                    selectedCategory === item.id
                      ? 'translate-x-[0%] h-auto overflow-auto'
                      : 'translate-x-[-50%] h-0 overflow-hidden'
                  "
                  class="transition-transform ease-in duration-250 px-2"
                >
                  <NuxtLink
                    class="menu-underline text-xl font-medium"
                    :to="generateLink('/shop/')"
                    @click="showMenu = false"
                  >
                    {{ t('shop.all') }}
                  </NuxtLink>

                  <li v-for="subItem in item.subItems" :key="subItem.id" class="mb-6" @click="showMenu = false">
                    <NuxtLink
                      class="menu-underline text-xl font-medium"
                      :to="generateLink(subItem.slug)"
                      @click="showMenu = false"
                    >
                      {{ subItem.title }}
                    </NuxtLink>
                  </li>
                  <li class="mt-auto lg:mb-0">
                    <h3 class="text-primary-1000 font-bold mb-2">{{ t('common.trending_products') }}</h3>
                    <div class="overflow-y-auto max-h-56 space-y-4 pr-2">
                      <SharedNavProductCard
                        is-search-sidebar
                        class="py-2 px-1"
                        :product="product"
                        v-for="product in trendingProducts?.data.slice(0, 2)"
                        :key="product.id"
                        @click="
                          () => {
                            showMenu = false;
                            gtm?.trackEvent({
                              event: 'view_item',
                              navigated_from: 'Mobile Shop Navigation Menu',
                              ecommerce: {
                                currency: 'AED',
                                value: formatPrice(product.price),
                                items: [
                                  {
                                    item_id: product.id,
                                    item_sku: product.sku,
                                    item_name: product.name,
                                    item_category: product.categories?.[0]?.name,
                                    item_category2: product.categories?.[1]?.name,
                                    item_category3: product.categories?.[2]?.name,
                                    price: formatPrice(product.price),
                                    discount: formatPrice(product.sale_price),
                                    quantity: 1,
                                  },
                                ],
                              },
                            });

                            viewProduct({
                              LanguageCode: locale,
                              ProductName: product.name,
                              ProductID: product.id,
                              SKU: product.sku,
                              Categories: product.categories?.map((category) => category.name).join(', '),
                              ImageURL: product.cover,
                              URL: route.fullPath,
                              Brand: 'Biogena',
                              Price: product.price,
                              PriceCurrency: 'AED',
                              CompareAtPrice: product.sale_price,
                            });
                          }
                        "
                      />
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
            <div class="mt-auto">
              <button class="text-xl font-medium" @click="showSiteSettings = true">
                <Icon name="tabler:settings" size="24" /> {{ t('common.site_preferences') }}
              </button>
            </div>
            <div class="bg-white fixed inset-0 px-6" v-if="showSiteSettings">
              <button
                class="absolute top-5 mt-2 mr-2 text-gray-700 hover:text-gray-900 ltr:right-2 ltr:mr-2 rtl:ml-2 rtl:left-2"
                @click="showSiteSettings = false"
              >
                <Icon name="tabler:x" class="w-6 h-6" />
              </button>

              <div value="mb-4">
                <h2 class="text-xl pt-6 font-bold ltr:text-left rtl:text-right">
                  {{ t('common.site_preferences') }}
                </h2>
              </div>

              <div class="pt-6">
                <span class="flex font-bold text-sm rtl:text-right">{{ t('common.country') }}</span>
                <MazSelect
                  :options="[
                    { label: t('forms.country.ae'), value: 'AE' },
                    { label: t('forms.country.qa'), value: 'QA' },
                    { label: t('forms.country.sa'), value: 'SA' },
                    { label: t('forms.country.om'), value: 'OM' },
                    { label: t('forms.country.bh'), value: 'BH' },
                    { label: t('forms.country.kw'), value: 'KW' },
                  ]"
                  class="text-sm w-full"
                  v-model="selectedCountry"
                />
              </div>

              <div class="pt-6">
                <span class="flex font-bold text-sm rtl:text-right">{{ t('common.currency') }}</span>
                <MazSelect
                  :options="
                    currencies.map((currency) => ({
                      value: currency.symbol,
                      label: t('common.' + currency.symbol) + ' - ' + t(currency.country),
                    }))
                  "
                  class="text-sm w-full"
                  v-model="selectedCurrency"
                />
                <button
                  class="w-full text-center py-[16px] mt-4 bg-primary-1000 text-white rounded-md font-medium hover:bg-primary-800"
                  @click="handleSave"
                >
                  {{ t('common.save') }}
                </button>
              </div>
            </div>
          </nav>
        </Transition>
      </div>
    </Transition>
  </div>
</template>
<script setup lang="ts">
import type { ProductsFetchResponse } from '~/interfaces/Product';
import { currencies } from '~/models/currencies';
import MazSelect from 'maz-ui/components/MazSelect';

const gtm = useGtm();
const route = useRoute();

const { t, locale, locales } = useI18n();

const availableLocales = computed(() => {
  return locales.value.filter((i) => i.code !== locale.value);
});

const showSiteSettings = ref(false);

const switchLocale = useSwitchLocalePath();
const showMenu = ref(false);

const mobileMenu = ref();
const selectedCategory = ref<Number | undefined>();

const { viewProduct } = useKlaviyo();

const menuStore = useMenuStore();
const { menuItems } = storeToRefs(menuStore);

onMounted(() => {
  onClickOutside(mobileMenu, () => (showMenu.value = false));
});

const onSelectCategory = (id: Number) => {
  !selectedCategory.value ? (selectedCategory.value = id) : (selectedCategory.value = undefined);
};

watch(showMenu, (value) => {
  if (value) {
    document.body.style.overflowY = 'hidden';
  } else {
    document.body.style.overflowY = '';
  }
});

const { data: trendingProducts } = await useLazyFetch<ProductsFetchResponse>('/api/products/tags/' + 'trending', {
  key: 'search-trending-products',
  headers: {
    'Accept-Language': locale.value.split('-')[0],
  },
  transform(input) {
    return {
      ...input,
      fetchedAt: new Date(),
    };
  },
});

watch(showMenu, (newValue) => {
  if (newValue && trendingProducts.value?.data && trendingProducts?.value?.data?.length > 1) {
    trendingProducts.value?.data?.sort(() => Math.random() - 0.5);
  }
});

const settingsStore = useSettingsStore();
const { userCurrency, userCountry } = storeToRefs(settingsStore);

const selectedCurrency = ref<string>(userCurrency.value || 'AED');
const selectedCountry = ref<string>(userCountry.value.countryCode || 'AE');

const handleSave = () => {
  settingsStore.setCurrency(selectedCurrency.value);
  settingsStore.setUserCountry({
    countryCode: selectedCountry.value,
    country: selectedCountry.value.toLowerCase(),
  });

  showSiteSettings.value = false;
  showMenu.value = false;
};

const countryCurrencyMap = {
  AE: 'AED',
  QA: 'QAR',
  SA: 'SAR',
  OM: 'OMR',
  BH: 'BHD',
  KW: 'KWD',
};

watch(selectedCountry, (newCountry) => {
  const newCurrency = countryCurrencyMap[newCountry] || 'AED'; // Default to AED if no match found
  selectedCurrency.value = newCurrency;
});
</script>

<style scoped>
.menu-underline::after {
  background: theme('colors.primary.400');
  content: '';
  display: block;
  height: 2px;
  margin: 0.8rem 0;
  width: 24px;
  opacity: 0.3;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 200ms ease;
}
.slide-enter-active {
  transition-delay: 10ms;
}
.slide-enter-from,
.slide-leave-to {
  transform: translateX(-100%);
}

[dir='rtl'] .slide-enter-from,
[dir='rtl'] .slide-leave-to {
  transform: translateX(100%);
}

.fade-enter-active,
.fade-leave-active {
  transition: all 250ms 150ms ease-out;
}
.fade-enter-active {
  transition-delay: 0ms;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-nav-enter-active,
.fade-nav-leave-active {
  transition: all 10ms ease-in;
}
.fade-nav-enter-active {
  transition-duration: 100ms;
}

.fade-nav-enter-from {
  transform: translateX(-50%);
  transition-timing-function: ease-in;
}
.fade-nav-leave-to {
  opacity: 0;
}
</style>
