<template>
  <div class="grid grid-cols-5 fixed bottom-0 left-0 right-0 bg-white border-t shadow-md p-4 z-[102] lg:hidden">
    <NuxtLink :to="generateLink('/')" class="flex flex-col items-center justify-center">
      <Icon
        :name="route.path == generateLink('/') ? 'clarity:home-solid' : 'clarity:home-line'"
        size="24"
        class="text-primary-1000"
      />
      <span class="text-xs text-primary-1000">{{ t('nav.home') }}</span>
    </NuxtLink>
    <button @click="showShop" class="flex flex-col items-center justify-center">
      <Icon :name="isShopVisible ? 'tabler:category-filled' : 'tabler:category'" size="24" class="text-primary-1000" />
      <span class="text-xs text-primary-1000" :class="isShopVisible ? 'font-bold' : ''">{{ t('nav.shop') }}</span>
    </button>
    <a
      @click="handleWhatsappClick"
      href="https://wa.me/971553385404"
      target="_blank"
      class="flex flex-col items-center justify-center"
    >
      <Icon name="tabler:brand-whatsapp" size="24" class="text-primary-1000" />
      <span class="text-xs text-primary-1000">{{ t('nav.contact') }}</span>
    </a>
    <NuxtLink :to="generateLink('/account')" class="flex flex-col items-center justify-center">
      <Icon name="tabler:user-circle" size="24" class="text-primary-1000" />
      <span class="text-xs text-primary-1000">{{ t('header.account') }}</span>
    </NuxtLink>
    <button class="relative flex flex-col items-center justify-center" @click="showCart">
      <div class="relative">
        <Icon
          :name="isCartVisible ? 'basil:shopping-bag-solid' : 'basil:shopping-bag-outline'"
          size="24"
          class="text-primary-1000"
        />
        <span
          class="absolute top-0 text-xs text-primary-1000 font-bold rounded-full"
          :class="totalItemsCount > 9 ? '-right-3.5' : '-right-1.5'"
          >{{ totalItemsCount > 0 ? totalItemsCount : '' }}</span
        >
      </div>

      <span class="text-xs text-primary-1000" :class="isCartVisible ? 'font-bold' : ''">{{ t('header.cart') }}</span>
    </button>

    <Transition>
      <CartSidebar position="bottom" @close="isCartVisible = false" v-if="isCartVisible" />
    </Transition>

    <Transition>
      <div
        v-if="isShopVisible"
        class="fixed inset-0 bg-white border py-4 px-4 shadow-sm overflow-y-auto max-h-[calc(100%-73px)] pb-12 z-[102]"
      >
        <div class="flex items-center border-b pb-2 mb-4">
          <h2 class="text-center font-bold ltr:ml-auto rtl:mr-auto">{{ t('nav.shop') }}</h2>
          <Icon
            name="tabler:x"
            size="18"
            class="cursor-pointer ltr:ml-auto rtl:mr-auto"
            @click="isShopVisible = false"
          />
        </div>
        <div class="grid grid-cols-2 items-start gap-4 max-h-full overflow-y-auto no-scrollbar">
          <NuxtLink class="relative col-span-2" :to="generateLink('/biogena-diamonds')" @click="isShopVisible = false">
            <div
              class="relative col-span-2 border rounded-md p-6 bg-cover bg-no-repeat after:absolute after:bg-gradient-to-l after:from-slate-50/20 after:via-slate-300/60 after:to-slate-800/40 after:inset-0 after:rounded-md"
              :style="`background-image: url('/diamonds_couple.jpg');`"
            >
              <h3 class="relative z-10 text-lg font-bold text-white">{{ t('shop.diamonds') }}</h3>
            </div>
          </NuxtLink>
          <NuxtLink :to="generateLink('/shop/')" @click="isShopVisible = false">
            <div
              class="relative flex items-center justify-center text-center h-full py-6 border rounded-md bg-cover bg-center after:absolute after:inset-0 after:rounded-md after:bg-primary-1000/40"
              :style="`background-image: url(${ms_clinic});`"
            >
              <h3 class="relative z-10 text-sm font-bold text-white">{{ t('shop.all') }}</h3>
            </div>
          </NuxtLink>

          <NuxtLink
            v-for="category in categories"
            class="relative flex items-center justify-center text-center h-full py-6 border rounded-md bg-cover bg-center after:absolute after:inset-0 after:rounded-md after:bg-primary-1000/40"
            :style="`background-image: url(${category.image})`"
            :to="generateLink('/shop/?category=' + category.slug)"
            @click="isShopVisible = false"
          >
            <h3 class="relative z-10 text-sm font-bold text-white">{{ category.name }}</h3>
          </NuxtLink>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import ms_clinic from '~/assets/images/ms_clinic.webp';

const isCartVisible = ref(false);
const isShopVisible = ref(false);

const gtm = useGtm();
const { t } = useI18n();

const route = useRoute();
const cartStore = useCartStore();
const categoryStore = useCategoryStore();

const { categories } = storeToRefs(categoryStore);
const { items, totalItemsCount, finalTotal } = storeToRefs(cartStore);

const showCart = () => {
  isShopVisible.value = false;
  isCartVisible.value = !isCartVisible.value;

  gtm?.trackEvent({
    event: 'view_cart',
    ecommerce: {
      currency: 'AED',
      value: formatPrice(finalTotal.value),
      cart_id: cartStore.cartUUID,
      items_quantity: totalItemsCount.value,
      items: items.value.map((item) => ({
        item_id: item.id,
        item_sku: item.sku,
        item_name: item.name,
        item_category: item.categories?.[0]?.name,
        item_category2: item.categories?.[1]?.name,
        item_category3: item.categories?.[2]?.name,
        price: formatPrice(item.price),
        discount: formatPrice(item.sale_price),
        quantity: item.quantity,
      })),
    },
  });
};

const showShop = () => {
  isCartVisible.value = false;
  isShopVisible.value = !isShopVisible.value;
};

const handleWhatsappClick = () => {
  gtm?.trackEvent({
    event: 'whatsapp_click',
    name: 'Whatsapp Click - Mobile',
  });
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: all 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  transform: translateY(120px);
  opacity: 0;
}
</style>
