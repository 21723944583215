<template>
  <div class="w-full flex items-center gap-x-4">
    <div>
      <img :src="product?.thumbnail" alt="" class="w-20 h-20 shrink-0 rounded-md object-contain bg-white" />
    </div>
    <div class="w-full flex justify-between items-center gap-x-4">
      <div class="flex flex-col items-start gap-y-4">
        <NuxtLink :to="generateLink('/product/' + product.slug)" @click="handleItemView(props.product)">
          <h3 class="text-sm font-medium">{{ product?.name }}</h3>
          <h4 class="text-xs text-primary-700">
            {{ product?.subtitle }}
          </h4>
        </NuxtLink>
        <div class="flex flex-col justify-between items-start">
          <h5 class="font-medium mb-1 whitespace-nowrap">
            {{ t('common.' + userCurrency) }}
            {{
              product.sale_price
                ? formatPrice(product.sale_price, userCurrency)
                : formatPrice(product.price, userCurrency)
            }}
          </h5>
        </div>
      </div>
      <button
        @click="handleAddToCart(props.product)"
        class="flex items-center justify-end gap-x-2 text-xs font-bold rounded-lg bg-primary-1000 text-primary-100 p-2 ltr:ml-auto rtl:mr-auto"
      >
        <div v-if="isCartLoading">
          <Icon name="tabler:loader-2" size="21" class="animate-spin text-primary-100" />
        </div>
        <div v-else>
          <Icon name="tabler:shopping-bag-plus" size="21" class="text-primary-100" />
        </div>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import type { Product } from '~/interfaces/Product';

const { t, locale } = useI18n();
const gtm = useGtm();
const config = useRuntimeConfig();

const props = defineProps({
  product: {
    type: Object as PropType<Product>,
    required: true,
  },
  reason: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(['closeSidebar', 'removeFromWishlist']);

const settingsStore = useSettingsStore();
const cartStore = useCartStore();

const { addToCart, viewProduct } = useKlaviyo();

const { userCurrency } = storeToRefs(settingsStore);
const { isCartLoading, items } = storeToRefs(cartStore);

const handleItemView = (product: Product) => {
  gtm?.trackEvent({
    event: 'view_item',
    navigated_from: 'Cart Upsell',
    ecommerce: {
      currency: 'AED',
      value: formatPrice(product.price),
      items: [
        {
          item_id: product.id,
          item_sku: product.sku,
          item_name: product.meta_title['en'],
          item_category: product.categories?.[0]?.name,
          item_category2: product.categories?.[1]?.name,
          item_category3: product.categories?.[2]?.name,
          price: formatPrice(product.price),
          discount: formatPrice(product.sale_price),
          quantity: 1,
        },
      ],
    },
  });

  viewProduct({
    LanguageCode: locale.value,
    ProductName: product.name,
    ProductID: product.id,
    SKU: product.sku,
    Categories: product.categories?.map((category) => category.name).join(', '),
    ImageURL: product.cover,
    URL: window.location.href,
    Brand: 'Biogena',
    Price: product.price,
    PriceCurrency: 'AED',
    CompareAtPrice: product.sale_price,
  });

  emit('closeSidebar');
};

const handleAddToCart = async (product: Product) => {
  gtm?.trackEvent({
    event: 'add_to_cart',
    added_from: 'Cart Upsell',
    ecommerce: {
      currency: 'AED',
      value: formatPrice(product.price),
      items: [
        {
          item_id: product.id,
          item_sku: product.sku,
          item_name: product.name,
          item_category: product.categories?.[0]?.name,
          item_category2: product.categories?.[1]?.name,
          item_category3: product.categories?.[2]?.name,
          price: formatPrice(product.price),
          discount: formatPrice(product.sale_price),
          quantity: 1,
        },
      ],
    },
  });

  addToCart({
    LanguageCode: locale.value,
    $value: product.price,
    AddedItemProductName: product.name,
    AddedItemProductID: product.id,
    AddedItemSKU: product.sku,
    AddedItemCategories: product.categories?.map((category) => category.name).join(', '),
    AddedItemImageURL: product.thumbnail,
    AddedItemURL: generateLink(`/product/${product.slug}`),
    AddedItemPrice: product.sale_price || product.price,
    AddedItemPriceCurrency: 'AED',
    AddedItemQuantity: 1,
    ItemNames: items.value.map((item) => item.name),
    CheckoutURL: config.public.APP_URL + '/checkout',
    Items: items.value.map((item) => ({
      ProductID: item.id,
      SKU: item.sku,
      ProductName: item.name,
      Quantity: item.quantity,
      ItemPrice: item.price,
      PriceCurrency: 'AED',
      RowTotal: item.price * item.quantity,
      ProductURL: generateLink(`/product/${item.slug}`),
      ImageURL: item.thumbnail,
      ProductCategories: item.categories?.map((category) => category.name),
    })),
  });

  await cartStore.addToCart(product, 1, locale.value);

  emit('removeFromWishlist');
};
</script>
