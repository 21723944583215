export const currencies = [
  {
    name: 'AED',
    country: 'forms.country.ae',
    countryCode: 'AE',
    symbol: 'AED',
    price: 1.0,
  },
  {
    name: 'QAR',
    country: 'forms.country.qa',
    countryCode: 'QA',
    symbol: 'QAR',
    price: 0.99115044,
  },
  {
    name: 'SAR',
    country: 'forms.country.sa',
    countryCode: 'SA',
    symbol: 'SAR',
    price: 1.0211028,
  },
  {
    name: 'KWD',
    country: 'forms.country.kw',
    countryCode: 'KW',
    symbol: 'KWD',
    price: 0.083590338,
  },
  {
    name: 'OMR',
    country: 'forms.country.om',
    countryCode: 'OM',
    symbol: 'OMR',
    price: 1.0480813,
  },
  {
    name: 'BHD',
    country: 'forms.country.bh',
    countryCode: 'BH',
    symbol: 'BHD',
    price: 0.10238257,
  },
];
