<template>
  <div
    class="fixed flex flex-col w-full bg-white z-[102] pt-4 pb-4 overflow-y-auto lg:w-[420px]"
    :class="
      position == 'right'
        ? 'h-full top-0 shadow-md ltr:right-0 rtl:left-0'
        : 'min-h-[300px] h-full max-h-[calc(100%-73px)] left-0 bottom-[73px]'
    "
    ref="cartSidebarRef"
  >
    <div class="flex items-center border-b px-4 pb-2 mb-4">
      <h2 class="text-center font-bold ltr:ml-auto rtl:mr-auto">{{ t('common.cart') }}</h2>
      <Icon name="tabler:x" size="18" class="cursor-pointer ltr:ml-auto rtl:mr-auto" @click="closeSidebar" />
    </div>

    <div class="h-full max-h-full overflow-y-auto lg:max-h-full">
      <div v-if="items.length > 0" v-for="item in items" class="border-b px-8">
        <CartItems :product="item" @close-sidebar="closeSidebar" />
      </div>
      <div v-else class="flex flex-col items-center">
        <Icon name="tabler:shopping-bag" size="64" class="mx-auto text-primary-200" />
        <p class="text-center text-primary-200">{{ t('common.cart_empty') }}</p>
      </div>
      <div class="mt-4" :class="items.length > 0 ? '' : 'border-t'">
        <h2 class="font-bold pt-4 px-8 mb-4 text-xl ltr:text-left rtl:text-right">
          {{ t('common.wishlist.title') }}
        </h2>
        <div
          v-if="user && wishlistItems && wishlistItems.length > 0"
          v-for="item in wishlistItems"
          class="border-b px-8 py-4"
        >
          <CartItemsUpsell
            :reason="''"
            :product="item"
            @close-sidebar="closeSidebar"
            @remove-from-wishlist="removeFromWishlist(item)"
          />
        </div>
        <div v-else-if="!user" class="px-8 py-4 text-center">
          <Icon name="solar:bag-heart-linear" size="60" class="text-primary-1000 mb-4" />
          <h1 class="px-4 font-bold text-primary-1000">{{ t('common.wishlist.login_title') }}</h1>
          <p class="text-sm font-medium">{{ t('common.wishlist.login') }}</p>
        </div>

        <div v-else class="px-8 py-4 text-center">
          <Icon name="solar:bag-heart-linear" size="60" class="text-primary-1000 mb-4" />
          <h1 class="px-4 font-bold text-primary-1000">{{ t('common.wishlist.empty') }}</h1>
        </div>
      </div>
    </div>

    <div v-if="filteredCartUpsellProducts && filteredCartUpsellProducts?.length > 0" class="mt-auto bg-primary-100 p-4">
      <div
        class="flex items-center justify-between cursor-pointer border-b py-1"
        :class="showUpsellProducts ? 'border-b' : 'border-b-transparent'"
        @click="showUpsellProducts = !showUpsellProducts"
      >
        <h3 class="text-sm font-medium capitalize">{{ t('common.you_may_also_like') }}</h3>
        <button>
          <Icon :name="showUpsellProducts ? 'tabler:chevron-down' : 'tabler:chevron-up'" size="18" />
        </button>
      </div>

      <Transition name="slide">
        <Swiper
          v-show="showUpsellProducts"
          class="upselling-products-swiper"
          :modules="[SwiperPagination, SwiperNavigation, SwiperAutoplay]"
          :slides-per-view="1"
          :loop="true"
          :pagination="{ enabled: true }"
        >
          <SwiperSlide v-for="product in filteredCartUpsellProducts" :key="product.id" class="py-4">
            <CartItemsUpsell
              :key="product.id"
              :reason="product.subtitle"
              :product="product"
              @close-sidebar="closeSidebar"
            />
          </SwiperSlide>
        </Swiper>
      </Transition>
    </div>

    <hr class="mt-auto mb-8" />

    <SharedAlert v-if="errorPromoCode" :message="errorPromoCode" :type="'error'" class="mx-8" />
    <span class="text-sm text-red-600 mx-8 mb-4" v-if="promoCode && discount <= 0">{{
      t('responses.promo.code_inapplicable')
    }}</span>

    <div class="relative flex items-center gap-x-4 mb-4 px-8">
      <input
        type="text"
        :placeholder="t('forms.placeholders.promo_code')"
        class="w-full border border-gray-300 rounded-md py-2 outline-none uppercase text-xs font-bold ltr:pl-4 ltr:pr-24 rtl:pr-4 rtl:pl-24"
        v-model="promoCodeInput"
        :disabled="isPromoCodeLoading || promoCode !== null"
      />
      <div class="absolute top-0 bottom-0 ltr:right-12 rtl:left-12">
        <button
          class="w-full h-full items-center rounded-tr-lg text-primary-1000 uppercase text-xs font-bold"
          @click="applyPromoCode"
        >
          <Icon name="tabler:loader-2" size="18" class="animate-spin" v-if="isPromoCodeLoading" />
          <span
            v-else="!isPromoCodeLoading"
            :class="promoCode !== null ? 'border border-primary-1000 px-2 py-1 rounded-md rtl:text-[9px]' : ''"
          >
            {{ promoCode !== null ? t('common.applied') : t('common.apply') }}
          </span>

          <button @click.stop="removePromoCode" class="relative z-10">
            <Icon name="material-symbols:cancel" size="22" class="ml-2 rtl:mr-2" v-if="promoCode !== null" />
          </button>
        </button>
      </div>
    </div>

    <div class="px-8">
      <div class="flex justify-between items-center font-medium">
        <p class="text-lg normal-case">{{ t('common.order_summary.total') }}</p>
        <small v-if="promoCode && discount" class="ml-auto line-through opacity-40 mr-2"
          >{{ t('common.' + userCurrency) }} {{ formatPrice(subtotal, userCurrency) }}</small
        >
        <p class="text-lg" :class="isCartLoading ? 'skeleton' : ''">
          {{ t('common.' + userCurrency) }} {{ formatPrice(total, userCurrency) }}
        </p>
      </div>
      <div v-if="promoCode && discount" class="flex justify-between items-center text-primary-900">
        <p class="text-sm font-medium">{{ t('common.discount') }}</p>
        <p class="text-sm">{{ t('common.' + userCurrency) }} -{{ formatPrice(discount, userCurrency) }}</p>
      </div>
      <div class="flex justify-between items-center">
        <p class="text-xs ml-auto">{{ t('common.tax_included') }}</p>
      </div>
    </div>

    <div class="mt-4 px-8">
      <CommonButton
        class="w-full justify-center items-center rounded-lg py-3"
        size="lg"
        :to="items.length > 0 ? generateLink('/checkout') : undefined"
        :class="items.length > 0 ? 'bg-primary-1000 text-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed'"
        @click="handleCheckout"
      >
        {{ t('common.checkout') }}
      </CommonButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';
import type { Product, ProductsFetchResponse } from '~/interfaces/Product';

defineProps({
  position: {
    type: String as PropType<'right' | 'bottom'>,
    default: 'right',
  },
});

// onMounted(async () => {
//   await removePromoCode();
// });

const config = useRuntimeConfig();
const gtm = useGtm();
const { locale, t } = useI18n();

const route = useRoute();

const emit = defineEmits(['close']);

const settingsStore = useSettingsStore();
const cartStore = useCartStore();
const wishlistStore = useWishlistStore();
const authStore = useAuthStore();

const { startCheckout } = useKlaviyo();

const { removePromoCode, showCart } = cartStore;
const {
  items,
  total,
  promoCode,
  isCartLoading,
  isPromoCodeLoading,
  discount,
  subtotal,
  errorPromoCode,
  cartUUID,
  totalItemsCount,
} = storeToRefs(cartStore);
const { wishlistItems } = storeToRefs(wishlistStore);
const { user } = storeToRefs(authStore);
const { userCurrency } = storeToRefs(settingsStore);

const promoCodeInput = ref(promoCode.value || route.query.promo_code?.toString() || '');

const cartSidebarRef = ref(null);
const showUpsellProducts = ref(true);

const handleCheckout = () => {
  gtm?.trackEvent({
    event: 'begin_checkout',
    ecommerce: {
      currency: 'AED',
      value: formatPrice(total.value),
      coupon: promoCode.value,
      items: items.value.map((item) => ({
        item_id: item.id,
        item_sku: item.sku,
        item_name: item.name,
        price: formatPrice(item.price),
        discount: formatPrice(item.sale_price),
        quantity: item.quantity,
        coupon: promoCode.value,
      })),
    },
  });

  startCheckout({
    LanguageCode: locale.value,
    $event_id: cartUUID.value + '-' + Math.floor(Date.now() / 1000),
    $value: total.value,
    ItemNames: items.value.map((item) => item.name),
    CheckoutURL: config.public.APP_URL + '/checkout',
    Items: items.value.map((item) => ({
      ProductID: item.id,
      SKU: item.sku,
      ProductName: item.name,
      Quantity: item.quantity,
      ItemPrice: item.price,
      RowTotal: item.price * item.quantity,
      ProductURL: generateLink(`/product/${item.slug}`),
      ImageURL: item.thumbnail,
      PriceCurrency: 'AED',
      ProductCategories: item.categories?.map((category) => category.name),
    })),
  });

  closeSidebar();
};

const closeSidebar = () => {
  emit('close');
};

onClickOutside(cartSidebarRef, () => {
  closeSidebar();
});

const applyPromoCode = async () => {
  await cartStore.applyPromoCode(promoCodeInput.value.toUpperCase());
};

const cartUpsellProducts = ref<Product[] | null>(null);

const filteredCartUpsellProducts = computed(() => {
  if (!cartUpsellProducts.value || !items.value) return null;

  return cartUpsellProducts.value.filter((upsellProduct) => !items.value.some((item) => item.id === upsellProduct.id));
});

await useLazyFetch<ProductsFetchResponse>('/api/products/tags/' + 'cart-upsell', {
  key: 'cart-upsell-products',
  headers: {
    'Accept-Language': locale.value.split('-')[0],
  },
  onResponse({ response }) {
    cartUpsellProducts.value = response._data.data;
  },
});

const removeFromWishlist = async (product: Product) => {
  try {
    await useFetch('/api/wishlist/delete-from-wishlist', {
      method: 'DELETE',
      body: {
        product_id: product.id,
      },
    });

    // Update the wishlist store
    wishlistStore.removeFromWishlist(product);
  } catch (error) {
    console.error('Error removing from wishlist:', error);
  }
};
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.3s, transform 0.3s;
}

.slide-enter-from,
.slide-leave-to {
  /* Slide */
  opacity: 0;
  transform: translateY(20px);
}
</style>
