import { currencies } from '~/models/currencies';

export function formatPrice(value: number | string = 0, currency: string = 'AED'): number | string {
  let number = parseFloat(value.toString()).toFixed(2);

  const currencyIndex = currencies.findIndex((c) => c.name === currency);
  if (currencyIndex === -1) {
    return number;
  } else {
    number = (parseFloat(number) * currencies[currencyIndex].price).toFixed(2);
    return number;
  }
}
