import type { SingleCategoryFetchResponse } from '~/interfaces/Category';
import type { Category } from '~/interfaces/Product';

export const useCategoryStore = defineStore('category', () => {
  const category = ref<SingleCategoryFetchResponse>();
  const categories = ref<Category[]>([]);
  const selectedCategoryProducts = ref<SingleCategoryFetchResponse>();
  const isCategoryLoading = ref<boolean>(false);
  const isCategoriesLoading = ref<boolean>(false);
  const isSelectedCategoryProductsLoading = ref<boolean>(false);

  const setSingleCategory = (data: SingleCategoryFetchResponse) => {
    category.value = data;
  };

  const setCategories = (data: Category[]) => {
    categories.value = data;
  };

  const setSelectedCategoryProducts = (data: SingleCategoryFetchResponse) => {
    selectedCategoryProducts.value = data;
  };

  const getCategorySlugById = (id: number): string | undefined => {
    const category = categories.value.find((c) => c.id === id);
    return category?.slug;
  };

  const getCategoryIdBySlug = (slug: string): number | undefined => {
    const category = categories.value.find((c) => c.slug === slug);
    return category?.id;
  };

  return {
    category,
    categories,
    selectedCategoryProducts,
    isCategoryLoading,
    isCategoriesLoading,
    isSelectedCategoryProductsLoading,
    getCategoryIdBySlug,
    getCategorySlugById,
    setCategories,
    setSingleCategory,
    setSelectedCategoryProducts,
  };
});
