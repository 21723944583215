<template>
  <Transition name="fade">
    <a
      @click="handleClick"
      :href="`https://wa.me/${settings?.whatsapp}`"
      target="_blank"
      v-if="isVisible"
      class="hidden fixed bottom-12 right-5 bg-white rounded-full p-4 border shadow-sm z-40 lg:block"
    >
      <Icon name="tabler:brand-whatsapp" size="24" class="text-primary-1000" />
    </a>
  </Transition>
</template>

<script setup lang="ts">
const { y } = useWindowScroll();
const isVisible = ref(false);

const gtm = useGtm();
const settingsStore = useSettingsStore();
const { settings } = storeToRefs(settingsStore);

watchEffect(() => {
  if (y.value > 100) {
    isVisible.value = true;
  } else {
    isVisible.value = false;
  }
});

const handleClick = () => {
  gtm?.trackEvent({
    event: 'whatsapp_click',
    name: 'Whatsapp Click - Desktop/Tablet',
  });
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateX(20px);
}
</style>
