import type { NavMenu } from '~/interfaces/NavMenu';

export const useMenuStore = defineStore('menu', () => {
  const categoryStore = useCategoryStore();
  const { t } = useI18n();

  const menuItems = ref<NavMenu[]>([
    { id: 1, title: 'nav.home', isClickable: true, slug: '/' },
    { id: 2, title: 'nav.about', isClickable: true, slug: '/why-biogena' },
    {
      id: 3,
      title: 'nav.shop',
      slug: '',
      isClickable: false,
      subItems: [],
    },
    {
      id: 4,
      title: 'nav.blog',
      slug: '/blog',
      isClickable: true,
    },
    {
      id: 5,
      title: 'nav.stories',
      slug: '/stories',
      isClickable: true,
    },
    {
      id: 6,
      title: 'nav.contact',
      slug: '/contact',
      isClickable: true,
    },
  ]);

  const fetchShopSubItems = () => {
    const shopItem = menuItems.value.find((item) => item.id === 3);
    if (!shopItem) return;

    shopItem.subItems = categoryStore.categories.map((category) => ({
      id: category.id,
      title: category.name,
      slug: `/shop/?category=${category.slug}`,
    }));
  };

  return {
    menuItems,
    fetchShopSubItems,
  };
});
