<template>
  <div
    class="bg-white border border-primary-100 shadow-md shadow-primary-100 rounded-2xl w-full my-2 cursor-pointer transition hover:scale-[1.02] hover:shadow-sm hover:outline hover:outline-1 hover:outline-primary-300"
    :class="{ 'lg:p-4 lg:my-0': !isSearchSidebar }"
  >
    <NuxtLink
      class="flex h-full w-full items-center"
      :class="{ 'lg:items-start lg:flex-col': !isSearchSidebar }"
      :to="generateLink('/product/' + product.slug)"
    >
      <div class="" :class="{ 'lg:mx-auto': !isSearchSidebar }">
        <NuxtImg
          :src="product.thumbnail"
          class="h-16 w-16 object-contain max-w-64"
          :class="{ 'lg:h-64 lg:w-full': !isSearchSidebar }"
        />
      </div>
      <div class="flex flex-col items-start justify-start h-full" :class="{ 'mt-4': !isSearchSidebar }">
        <h3 class="font-bold capitalize text-primary-1000 ltr:text-left rtl:text-right">{{ product.name }}</h3>
        <h2 class="text-sm tracking-wider text-primary-500 ltr:text-left rtl:text-right">
          {{ product.subtitle }}
        </h2>
        <p class="mt-auto font-medium">
          {{ t('common.' + userCurrency) }}
          {{
            product.sale_price
              ? formatPrice(product.sale_price, userCurrency)
              : formatPrice(product.price, userCurrency)
          }}
        </p>
      </div>
    </NuxtLink>
  </div>
</template>

<script lang="ts" setup>
import type { Product } from '~/interfaces/Product';

const { t } = useI18n();

const settingsStore = useSettingsStore();
const { userCurrency } = storeToRefs(settingsStore);

defineProps({
  product: {
    type: Object as PropType<Product>,
    required: true,
  },
  isSearchSidebar: {
    type: Boolean,
    default: false, // Adjust this based on your needs
  },
});
</script>
