<template>
  <LayoutMainLayout>
    <div class="container mb-12 mt-32 px-4 lg:mt-60">
      <div class="text-center">
        <h1 class="text-6xl font-bold">{{ error.statusCode }}</h1>
        <p class="text-xl md:text-3xl font-semibold mt-3 mb-8">{{ error.message }}</p>
        <p class="mb-8 text-lg text-gray-700">
          The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.

          
        </p>
        <button @click="handleError" class="px-6 py-2 text-sm font-semibold rounded bg-primary-1000 text-white">
          Go Home
        </button>
      </div>
    </div>
  </LayoutMainLayout>
</template>

<script setup lang="ts">
defineProps({
  error: Object,
});

const handleError = () => clearError({ redirect: generateLink('/') });
</script>
