<template>
  <div>
    <LayoutHeader v-if="route.path !== generateLink('/checkout')" />

    <main :class="route.path === generateLink('/checkout') ? '' : 'mt-20'">
      <div v-if="loading" class="fixed bg-white/70 backdrop-blur inset-0 z-[99999]">
        <CommonBiogenaLoader
          class="absolute top-1/2 -translate-y-1/2 scale-[2] z-[9999] ltr:left-1/2 ltr:-translate-x-1/2 rtl:right-1/2 rtl:translate-x-1/2"
        />
      </div>

      <slot />
    </main>

    <LazyLayoutFooter v-if="route.path !== generateLink('/checkout')" />
    <LayoutMobileBottomNavigation v-if="route.path !== generateLink('/checkout')" />
    <SharedFloatingWhatsapp v-if="route.path !== generateLink('/checkout')" />
  </div>
</template>

<script setup lang="ts">
const nuxtApp = useNuxtApp();
const loading = ref(false);

const route = useRoute();

nuxtApp.hook('page:start', () => {
  loading.value = true;
});

nuxtApp.hook('page:finish', () => {
  loading.value = false;
});
</script>

<style scoped></style>
