<template>
  <div class="fixed top-0 left-0 right-0 z-[101] bg-white border-b">
    <AnnouncementBanner />
    <div class="container grid grid-cols-12 justify-center items-center px-4 py-2 my-1 lg:p-4 lg:my-0 lg:px-8">
      <LayoutHeaderMobileNav class="col-span-3 lg:hidden" />

      <div class="hidden col-span-3 items-center justify-end lg:block">
        <button @click="showSearch = true" class="">
          <Icon name="mingcute:search-line" size="26" class="text-primary-1000" />
          <span class="text-primary-1000 font-bold px-2"> {{ t('header.search') }} </span>
        </button>
      </div>

      <div class="col-span-6 items-center lg:col-span-6">
        <div class="flex items-center justify-center">
          <NuxtLink :to="generateLink('/')">
            <img src="~/assets/images/biogena_logo.svg" class="w-full max-w-32 lg:max-w-64" />
          </NuxtLink>
        </div>
      </div>

      <div class="col-span-3 flex justify-end lg:hidden">
        <button @click="showSearch = true" class="text-gray-800 p-2">
          <Icon name="tabler:search" size="18" class="text-primary-1000" />
        </button>
      </div>

      <Transition :name="isMobile ? 'slide-from-right' : 'slide-from-left'">
        <LazySearchFullScreenSearchOverlay class="lg:w-[400px] z-50" v-if="showSearch" @close="showSearch = false" />
      </Transition>

      <div class="hidden col-span-3 lg:block">
        <div class="flex items-center justify-end gap-x-2 xl:gap-x-4">
          <div class="flex relative border-primary-300 ltr:border-r ltr:pr-4 rtl:border-l rtl:pl-4">
            <div
              @click="showCurrencyModal = true"
              class="font-bold text-primary-1000 text-xs whitespace-nowrap pt-1 cursor-pointer"
            >
              {{ t('common.' + userCurrency) }}
              {{ userCountry.countryCode ? ' - ' + t('forms.country.' + userCountry.countryCode.toLowerCase()) : '' }}
            </div>
          </div>

          <NuxtLink
            :to="
              locale === 'en' || locale === `en-${userCountry.countryCode.toLowerCase()}`
                ? switchLocale(userCountry.countryCode === 'AE' ? 'ar' : `ar-${userCountry.countryCode.toLowerCase()}`)
                : switchLocale(userCountry.countryCode === 'AE' ? 'en' : `en-${userCountry.countryCode.toLowerCase()}`)
            "
            class="flex relative border-primary-300 ltr:border-r ltr:pr-4 rtl:border-l rtl:pl-4"
          >
            <div class="flex items-center font-bold text-primary-1000">
              {{ locale == 'ar' ? 'English' : 'عربي' }}
            </div>
          </NuxtLink>

          <NuxtLink class="hidden lg:block" :to="generateLink('/account')">
            <Icon name="tabler:user-circle" size="34" class="text-primary-1000" />
          </NuxtLink>

          <NuxtLink
            class="hidden cursor-pointer lg:block"
            @click="showWishlistModal = true"
            :to="user ? generateLink('account/wishlist') : ''"
          >
            <Icon name="ph:heart-bold" size="34" class="text-primary-1000" />
          </NuxtLink>

          <div class="hidden relative flex-col items-center lg:flex">
            <button @click="handleShowCart">
              <Icon name="tabler:shopping-bag" size="34" class="text-primary-1000" />
              <span
                class="flex items-center justify-center absolute top-0 w-6 h-6 bg-primary-600 font-bold rounded-full text-sm pt-1 text-white ltr:right-[-15px] rtl:left-[-15px]"
              >
                {{ totalItemsCount }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <LayoutHeaderDesktopNav class="hidden lg:flex" />

    <div v-if="showCart || showSearch" class="overlay" @click="setShowOverlay"></div>

    <Transition>
      <LazyCartSidebar @close="setShowCart(false)" v-if="showCart" />
    </Transition>

    <div v-if="!user">
      <LazyModalLoginModal
        v-if="showLoginModal"
        :is-open="showLoginModal"
        :show-login-form="showLoginForm"
        @close="
          () => {
            showLoginForm = false;
            showLoginModal = false;
          }
        "
        :login-redirect="'account/wishlist'"
      >
        <div v-if="!showLoginForm" class="text-center pt-12">
          <Icon name="solar:bag-heart-linear" size="60" class="text-primary-1000 mb-4" />
          <h1 class="px-4 font-bold text-primary-1000">{{ t('common.wishlist.login_title') }}</h1>
          <p class="text-sm font-medium">{{ t('common.wishlist.login') }}</p>
          <CommonButton
            @click="showLoginForm = true"
            class="mx-auto py-2 mt-6 mb-12 w-full max-w-40 flex items-center justify-center"
            >{{ t('common.sign_in') }}</CommonButton
          >
        </div>
      </LazyModalLoginModal>
    </div>

    <LazyModalCurrencyModal v-if="showCurrencyModal" :is-open="showCurrencyModal" @close="showCurrencyModal = false" />
  </div>
</template>

<script setup lang="ts">
const gtm = useGtm();

const { t, locale } = useI18n();
const { isMobile } = useDevice();

// const availableLocales = computed(() => {
//   return locales.value.filter((i) => i.code !== locale.value);
// });

const switchLocale = useSwitchLocalePath();

const settingsStore = useSettingsStore();
const authStore = useAuthStore();
const menuStore = useMenuStore();
const categoryStore = useCategoryStore();
const cartStore = useCartStore();

const { user, showLoginModal } = storeToRefs(authStore);
const { userCurrency, userCountry } = storeToRefs(settingsStore);

const { setCategories } = categoryStore;
const { setShowCart } = cartStore;
const { fetchShopSubItems } = menuStore;
const { items, totalItemsCount, showCart, finalTotal } = storeToRefs(cartStore);

const showSearch = ref(false);
const showLoginForm = ref(false);
const showWishlistModal = ref(false);
const showCurrencyModal = ref(false);

const fetchData = async () => {
  try {
    await useLazyFetch('/api/categories/categories', {
      key: 'nav-categories',
      onResponse({ response }) {
        setCategories(response._data.data);
        fetchShopSubItems();
      },
      headers: {
        'Accept-Language': locale.value.split('-')[0],
      },
      transform(input) {
        return {
          ...(input as any),
          fetchedAt: new Date(),
          language: locale.value,
        };
      },
      getCachedData(key, nuxtApp) {
        const data = nuxtApp.payload.data[key] || nuxtApp.static.data[key];

        if (!data) {
          return;
        }

        const expirationDate = new Date(data.fetchedAt);
        expirationDate.setTime(expirationDate.getTime() + 3600 * 1000);
        const isExpired = expirationDate.getTime() < Date.now();

        if (isExpired) {
          return;
        }

        if (locale.value !== data.language) {
          return;
        }

        return data;
      },
    });
  } catch (error) {
    console.error(error);
  }
};

fetchData();

const handleShowCart = () => {
  setShowCart(true);

  gtm?.trackEvent({
    event: 'view_cart',
    ecommerce: {
      currency: 'AED',
      value: formatPrice(finalTotal.value),
      cart_id: cartStore.cartUUID,
      items_quantity: totalItemsCount.value,
      items: items.value.map((item) => ({
        item_id: item.id,
        item_sku: item.sku,
        item_name: item.name,
        item_category: item.categories?.[0]?.name,
        item_category2: item.categories?.[1]?.name,
        item_category3: item.categories?.[2]?.name,
        price: formatPrice(item.price),
        discount: formatPrice(item.sale_price),
        quantity: item.quantity,
      })),
    },
  });
};

const setShowOverlay = () => {
  showSearch.value = false;
  setShowCart(false);
};

watch(showSearch, (value) => {
  if (value) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = '';
  }
});

watch(user, (value) => {
  if (value) {
    showWishlistModal.value = false;
  }
});

watch(locale, (newLocale, oldLocale) => {
  if (newLocale !== oldLocale) {
    fetchData();
  }
});
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  transform: translateX(420px);
}

[dir='rtl'] .v-enter-from,
[dir='rtl'] .v-leave-to {
  transform: translateX(-420px);
}

.slide-from-right-enter-active,
.slide-from-right-leave-active {
  transition: all 0.3s ease;
}

.slide-from-right-enter-from,
.slide-from-right-leave-to {
  transform: translateX(100%);
  @apply lg:-translate-x-full;
}

.slide-from-left-enter-active,
.slide-from-left-leave-active {
  transition: all 0.3s ease;
}

.slide-from-left-enter-from,
.slide-from-left-leave-to {
  transform: translateX(-100%);
}

[dir='rtl'] .slide-from-right-enter-from,
[dir='rtl'] .slide-from-right-leave-to {
  transform: translateX(-100%);
}

[dir='rtl'] .slide-from-left-enter-from,
[dir='rtl'] .slide-from-left-leave-to {
  transform: translateX(100%);
}
</style>
