import { en, ar } from '@formkit/i18n';
import { defineFormKitConfig } from '@formkit/vue';
import { generateClasses } from '@formkit/themes';
import formkitTailwindTheme from './formkit.theme';

export default defineFormKitConfig({
  locales: { en, ar },
  locale: 'en',
  config: {
    classes: generateClasses(formkitTailwindTheme),
  },
});
