import type { AuthProviders } from '~/interfaces/Auth';
import type { RegisterProps, User, UserFetchResponse } from '~/interfaces/User';
import { setCustomCookie } from '~/utils/cookies';

export const useAuthStore = defineStore(
  'auth',
  () => {
    const { $http } = useNuxtApp();

    const alertStore = useAlertStore();

    const { setAlert, clearAlert } = alertStore;
    const config = useRuntimeConfig();
    const router = useRouter();

    const user = ref<User | null>(null);

    const isSocialAuthenticating = ref<boolean>(false);
    const isAuthenticating = ref<boolean>(false);
    const showLoginModal = ref<boolean>(false);

    const setUser = (data: UserFetchResponse) => {
      user.value = data.user;
    };

    const OAuth_Providers: AuthProviders = {
      google: `${config.public.OAUTH_REDIRECT_URL}/oauth/google/redirect`,
      facebook: `${config.public.OAUTH_REDIRECT_URL}/oauth/facebook/redirect`,
      apple: `${config.public.OAUTH_REDIRECT_URL}/oauth/apple/redirect`,
    };

    const authenticateSocialLogin = async (service: string, code: string) => {
      isSocialAuthenticating.value = true;
      try {
        const response = await $http.post('login', {
          provider: service,
          code: code,
        });

        if (response.status == 200) {
          const data: { token: string } = response.data.data;
          const user = response.data.data.user;
          setCustomCookie('token', data.token);

          if (user.email === null) {
            router.push(generateLink('/login/update'));
          } else {
            await useFetch<UserFetchResponse>('/api/me', {
              onResponse({ response }) {
                setUser(response._data.data);
              },
            });
            router.push(generateLink('account'));
          }
        }
      } catch (e: any) {
        console.error('Error: ', e);
        setAlert('Something went wrong, please try again', 'error');
      } finally {
        isSocialAuthenticating.value = false;
      }
    };

    const register = async (data: RegisterProps) => {
      isAuthenticating.value = true;
      try {
        const response = await $http.post('register', data);

        if (response.status == 200) {
          const data: { token: string } = response.data.data;
          setCustomCookie('token', data.token);
          await useFetch<UserFetchResponse>('/api/me', {
            onResponse({ response }) {
              setUser(response._data.data);
            },
          });
          router.push(generateLink('account'));
        }
      } catch (e: any) {
        console.error('Error: ', e);

        const errors = e.response.data.message;

        Object.keys(errors).forEach((key) => {
          Array.isArray(errors[key])
            ? errors[key].forEach((error: any) => {
                setAlert(error, 'error');
              })
            : setAlert(errors[key], 'error');
        });
      } finally {
        isAuthenticating.value = false;
      }
    };

    const login = async (email: string, password: string, destination = 'account') => {
      isAuthenticating.value = true;
      try {
        const response = await $http.post('login', {
          email: email,
          password: password,
        });

        if (response.status == 200) {
          const data: { token: string } = response.data.data;
          setCustomCookie('token', data.token);
          await useFetch<UserFetchResponse>('/api/me', {
            onResponse({ response }) {
              setUser(response._data.data);
            },
          });

          router.push(generateLink(destination));
        }
      } catch (e: any) {
        console.error('Error: ', e);
        setAlert('The entered email or password is invalid', 'error');
      } finally {
        isAuthenticating.value = false;
      }
    };

    const loginWithMagicLink = async (email: string) => {
      isAuthenticating.value = true;
      try {
        const response = await $http.post('login/magic/request', {
          email: email,
        });

        if (response.status == 200) {
          setAlert('Magic link sent to your email', 'success');
        }
      } catch (e: any) {
        console.error('Error: ', e);
        setAlert('The entered email is invalid', 'error');
      } finally {
        isAuthenticating.value = false;
      }
    };

    const loginWithMagicLinkToken = async (magic_token: string) => {
      isAuthenticating.value = true;
      try {
        const response = await $http.post('login/magic', {
          token: magic_token,
        });

        if (response.status == 200) {
          const data = response.data.data;
          setCustomCookie('token', data.token);

          await useFetch<UserFetchResponse>('/api/me', {
            onResponse({ response }) {
              setUser(response._data.data);
            },
          });
          router.replace(generateLink('/account'));
        } else {
          setAlert('Invalid magic link', 'error');
          router.replace(generateLink('/login'));
        }
      } catch (e: any) {
        console.error('Error: ', e);
        setAlert(e.response.data.message, 'error');
        router.replace(generateLink('/login'));
      } finally {
        isAuthenticating.value = false;
      }
    };

    const logout = () => {
      user.value = null;
      deleteCustomCookie('token');
      navigateTo(generateLink('/login'), {
        replace: true,
      });
    };

    return {
      user,
      OAuth_Providers,
      isSocialAuthenticating,
      isAuthenticating,
      showLoginModal,
      login,
      loginWithMagicLink,
      loginWithMagicLinkToken,
      register,
      logout,
      setUser,
      authenticateSocialLogin,
      setAlert,
      clearAlert,
    };
  },

  {
    persist: {
      key: 'user',
      paths: ['user'],
      storage: persistedState.cookiesWithOptions({
        maxAge: 60 * 60 * 24 * 7, // 1 week
        sameSite: 'lax',
        watch: true,
      }),
    },
  },
);
