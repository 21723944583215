<template>
  <div
    v-if="isCartLoading"
    class="relative grid grid-cols-4 items-center gap-x-4 my-4 after:absolute after:inset-0 after:bg-white/80 after:animate-pulse"
  >
    <div class="col-span-1">
      <img :src="product.thumbnail" alt="" class="w-20 h-20 rounded-md object-contain bg-white" />
    </div>
    <div class="col-span-3 grid grid-cols-4">
      <div class="col-span-3 flex flex-col items-start">
        <h3 class="text-sm font-medium">{{ product.name }}</h3>
        <h4 class="text-xs text-primary-700">
          {{ product.subtitle }}
        </h4>
        <div class="mt-auto w-1/3">
          <SharedQuantitySelector
            showQuantityButtons
            :showLabel="false"
            isCart
            class="mt-4"
            v-model="selectedQuantity"
            @removeFromCart="deleteItem"
          />
        </div>
      </div>
      <div class="flex flex-col justify-between items-end">
        <button class="" @click="deleteItem" :disabled="isCartLoading">
          <Icon name="tabler:x" size="16" class="text-primary-1000" />
        </button>
        <h5 class="font-medium mb-1 whitespace-nowrap">
          {{ t('common.' + userCurrency) }}
          {{
            product.sale_price
              ? formatPrice(product.sale_price, userCurrency)
              : formatPrice(product.price, userCurrency)
          }}
        </h5>
      </div>
    </div>
  </div>
  <div v-else-if="!isCartLoading" class="grid grid-cols-4 items-center gap-x-4 my-4">
    <div class="col-span-1">
      <img :src="product.thumbnail" alt="" class="w-20 h-20 rounded-md object-contain bg-white" />
    </div>
    <div class="col-span-3 grid grid-cols-4">
      <div class="col-span-3 flex flex-col items-start">
        <NuxtLink :to="generateLink('/product/' + product.slug)" @click="handleItemView">
          <h3 class="text-sm font-medium">{{ product.name }}</h3>
          <h4 class="text-xs text-primary-700">
            {{ product.subtitle }}
          </h4>
        </NuxtLink>
        <div class="mt-auto w-1/3">
          <SharedQuantitySelector
            showQuantityButtons
            :showLabel="false"
            isCart
            class="relative mt-4"
            v-model="selectedQuantity"
            @removeFromCart="deleteItem"
          />
        </div>
      </div>
      <div class="flex flex-col justify-between items-end">
        <button class="flex" @click="deleteItem" :disabled="isCartLoading">
          <Icon v-if="!isCartLoading" name="tabler:x" size="16" class="text-primary-1000 shrink-0" />
          <Icon v-else name="tabler:loader" size="16" class="animate-spin text-primary-1000" />
        </button>
        <!-- Price display with discount handling -->
        <div class="flex flex-col items-end">
          <span
            v-if="product.discount && product.discount > 0"
            class="font-medium text-xs mb-1 whitespace-nowrap line-through"
          >
            {{ t('common.' + userCurrency) }}
            {{
              product.sale_price > 0
                ? formatPrice(product.sale_price, userCurrency)
                : formatPrice(product.price, userCurrency)
            }}
          </span>
          <span
            class="mb-1 whitespace-nowrap"
            :class="product.discount && product.discount > 0 ? 'text-primary-1000 font-bold' : 'font-medium'"
          >
            {{ t('common.' + userCurrency) }}
            {{
              product.sale_price && product.sale_price < product.price
                ? product.discount && product.discount > 0
                  ? formatPrice(product.sale_price - product.discount, userCurrency)
                  : formatPrice(product.sale_price, userCurrency)
                : product.discount && product.discount > 0
                ? formatPrice(product.price - product.discount, userCurrency)
                : formatPrice(product.price, userCurrency)
            }}
          </span>
        </div>
      </div>

      <button
        v-if="showSaveToWishlist"
        class="text-xs rounded-md col-span-4 w-full gap-2 flex justify-end mt-1 text-primary-1000 underline"
        @click="handleAddtoWishlist(product.id)"
      >
        {{ t('common.save_to_wishlist') }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Product } from '~/interfaces/Product';

const gtm = useGtm();
const { locale, t } = useI18n();

const props = defineProps({
  product: {
    type: Object as PropType<Product>,
    required: true,
  },
  showSaveToWishlist: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['closeSidebar']);

const { viewProduct } = useKlaviyo();

const settingsStore = useSettingsStore();
const cartStore = useCartStore();
const wishlistStore = useWishlistStore();
const authStore = useAuthStore();

const { userCurrency, userCountry } = storeToRefs(settingsStore);
const { isCartLoading, items, promoCode, cartUUID, shippingRates } = storeToRefs(cartStore);
const { user } = storeToRefs(authStore);

const selectedQuantity = ref(items.value.find((item) => item.id === props.product.id)?.quantity || 1);

watch(selectedQuantity, async (newQuantity) => {
  await cartStore.updateCart(props.product.id, newQuantity);

  if (userCountry.value.countryCode !== 'AE') {
    await useFetch('/api/rate-calculator', {
      key: 'rate-calculator',
      method: 'POST',
      body: {
        cartUuid: cartUUID.value,
      },
      onResponse({ response }) {
        shippingRates.value = response._data.data;
      },
    });
  }
});

const route = useRoute();

const handleItemView = () => {
  gtm?.trackEvent({
    event: 'view_item',
    navigated_from: 'Cart',
    ecommerce: {
      currency: 'AED',
      value: formatPrice(props.product.price),
      items: [
        {
          item_id: props.product.id,
          item_sku: props.product.sku,
          item_name: props.product.meta_title['en'],
          item_category: props.product.categories?.[0]?.name,
          item_category2: props.product.categories?.[1]?.name,
          item_category3: props.product.categories?.[2]?.name,
          price: formatPrice(props.product.price),
          discount: formatPrice(props.product.sale_price),
          quantity: selectedQuantity.value,
        },
      ],
    },
  });

  viewProduct({
    LanguageCode: locale.value,
    ProductName: props.product.meta_title['en'],
    ProductID: props.product.id,
    SKU: props.product.sku,
    Categories: props.product.categories?.map((category) => category.name).join(', '),
    ImageURL: props.product.cover,
    URL: window.location.href,
    Brand: 'Biogena',
    Price: props.product.price,
    PriceCurrency: 'AED',
    CompareAtPrice: props.product.sale_price,
  });

  emit('closeSidebar');
};

const deleteItem = async () => {
  gtm?.trackEvent({
    event: 'remove_from_cart',
    ecommerce: {
      currency: 'AED',
      value: formatPrice(props.product.price),
      items: [
        {
          item_id: props.product.id,
          item_name: props.product.meta_title['en'],
          item_category: props.product.categories?.[0]?.name,
          item_category2: props.product.categories?.[1]?.name,
          item_category3: props.product.categories?.[2]?.name,
          price: formatPrice(props.product.price),
          discount: formatPrice(props.product.sale_price),
          quantity: selectedQuantity.value,
        },
      ],
    },
  });

  await cartStore.deleteFromCart(props.product.id);
  if (route.path === generateLink('/checkout') && !items.value.length) {
    navigateTo(generateLink('/'));
  }

  if (items.value.length === 0) {
    promoCode.value = null;
  }
};

const handleAddtoWishlist = async (productId: number) => {
  if (!user.value) {
    authStore.showLoginModal = true;
    return;
  }

  try {
    await useFetch('/api/wishlist/add-to-wishlist', {
      method: 'POST',

      body: {
        products: [productId],
      },
    });

    // Update the wishlist store
    wishlistStore.addToWishlist(props.product);
    deleteItem();
  } catch (error) {
    console.error('Error adding to wishlist:', error);
  }
};
</script>
