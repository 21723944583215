export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  nuxtApp.hook('app:mounted', () => {
    // Dynamically inject the Klaviyo script
    const script = document.createElement('script');
    script.async = true;
    script.type = 'text/javascript';
    script.src = '//static.klaviyo.com/onsite/js/klaviyo.js?company_id=' + config.public.KLAVIYO_PUBLIC_API_KEY;
    document.body.appendChild(script);

    script.onload = () => {
      const authStore = useAuthStore();
      const { $i18n } = useNuxtApp();

      if (!authStore) {
        console.log('authStore not found');
        return;
      }

      const identifyUser = (email: string) => {
        window.klaviyo = window.klaviyo || [];
        window.klaviyo.push([
          'identify',
          {
            email: email,
            $first_name: authStore.user?.first_name,
            $last_name: authStore.user?.last_name,
            $phone_number: authStore.user?.phone_number,
            'Preferred Language': authStore.user?.preferred_language || $i18n.locale.value,
          },
        ]);
      };

      if (authStore.user?.email) {
        identifyUser(authStore.user.email);
      }

      watch(
        () => authStore.user?.email,
        (email) => {
          if (email) {
            identifyUser(email);
          }
        },
        { immediate: true },
      );
    };
  });
});
