import type { Product } from '~/interfaces/Product';

export const useWishlistStore = defineStore(
  'wishlist',
  () => {
    const wishlistItems = ref<Product[] | null>([]);

    // Actions / Mutations
    const addToWishlist = (product: Product) => {
      if (!wishlistItems.value?.includes(product)) {
        wishlistItems.value?.push(product);
      }
    };

    const removeFromWishlist = (product: Product) => {
      if (!wishlistItems.value) return;

      const index = wishlistItems.value.findIndex((p) => p.id === product.id);
      if (index !== -1) {
        wishlistItems.value?.splice(index, 1);
      }
    };

    const setWishlistItems = (data: Product[]) => {
      wishlistItems.value = data;
    };

    const reset = () => {
      wishlistItems.value = null;
    };

    return {
      wishlistItems,
      setWishlistItems,
      addToWishlist,
      removeFromWishlist,
      reset,
    };
  },
  {
    persist: {
      key: 'wishlist',
      storage: persistedState.localStorage,
    },
  },
);
