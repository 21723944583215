import { DeliveryMethod, PaymentMethod, type Address, type CustomerDetails } from '~/interfaces/Checkout';
import type { Order } from '~/interfaces/Order';

export const useCheckoutStore = defineStore(
  'checkout',
  () => {
    const settingsStore = useSettingsStore();
    const { userCountry } = storeToRefs(settingsStore);

    const customerDetails = ref<CustomerDetails>({
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
    });

    const deliveryMethod = ref<DeliveryMethod | null>(null);

    const paymentMethod = ref<PaymentMethod>();

    const address = ref<Address>({
      latitude: 0,
      longitude: 0,
      street: '',
      city: '',
      country: userCountry.value.countryCode.toUpperCase() || 'AE',
      building: '',
      area: '',
      nearestLandmark: '',
    });

    const customerNotes = ref<string>('');

    const showTabbyPayment = ref<boolean>(true);

    const order = ref<Order>();

    const stripeClientSecret = ref<string>('');

    function setCustomerDetails(newDetails: CustomerDetails) {
      customerDetails.value = newDetails;
    }

    function setDeliveryMethod(method: DeliveryMethod | null) {
      deliveryMethod.value = method;
    }

    function setPaymentMethod(method: PaymentMethod) {
      paymentMethod.value = method;
    }

    function setAddress(newAddress: Address) {
      address.value = newAddress;
    }

    function setStripeClientSecret(secret: string) {
      stripeClientSecret.value = secret;
    }

    function setOrder(newOrder: Order) {
      order.value = newOrder;
    }

    function setCustomerNotes(notes: string) {
      customerNotes.value = notes;
    }

    function reset() {
      customerDetails.value = {
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
      };
      deliveryMethod.value = null;
      paymentMethod.value = undefined;
      address.value = {
        latitude: 0,
        longitude: 0,
        street: '',
        city: '',
        country: userCountry.value.countryCode.toUpperCase() || 'AE',
        building: '',
        area: '',
        nearestLandmark: '',
      };
      stripeClientSecret.value = '';
    }

    const isCustomerDetailsValid = computed(() => {
      const { first_name, last_name, email, phone_number } = customerDetails.value;
      return first_name && last_name && email && phone_number;
    });

    const isDeliveryMethodValid = computed(() => {
      return deliveryMethod.value !== null;
    });

    const isAddressValid = computed(() => {
      const { country, city, area, street, building } = address.value;

      return country && city && area && street && building;
    });

    const isPaymentMethodValid = computed(() => {
      return paymentMethod.value !== undefined;
    });

    return {
      order,
      customerDetails,
      deliveryMethod,
      paymentMethod,
      address,
      customerNotes,
      showTabbyPayment,
      stripeClientSecret,
      setCustomerDetails,
      setStripeClientSecret,
      setDeliveryMethod,
      setPaymentMethod,
      setAddress,
      setOrder,
      setCustomerNotes,
      reset,
      isCustomerDetailsValid,
      isDeliveryMethodValid,
      isAddressValid,
      isPaymentMethodValid,
    };
  },

  {
    persist: {
      paths: ['customerDetails', 'deliveryMethod', 'address'],
      storage: persistedState.cookiesWithOptions({
        maxAge: 60 * 60 * 24 * 7, // 1 week
        sameSite: 'lax',
        secure: true,
        httpOnly: true,
        watch: true,
      }),
    },
  },
);
