export function setCustomCookie(name: string, value: string) {
  if (process.client) {
    const date = new Date();

    // 7 days expiry
    date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);

    document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/;secure;SameSite=Lax`;
  }
}

export function getCustomCookie(name: string) {
  if (process.client) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    if (parts.length === 2) {
      return parts.pop()?.split(';').shift();
    }
  }
  return null;
}

export function deleteCustomCookie(name: string) {
  if (process.client) {
    const date = new Date();

    date.setTime(date.getTime() - 1 * 24 * 60 * 60 * 1000);

    document.cookie = `${name}=;expires=${date.toUTCString()};path=/;secure;SameSite=Lax`;
  }
}
