import type { CartItem, Cart } from '~/interfaces/Cart';
import type { Product } from '~/interfaces/Product';
import { DeliveryMethod, PaymentMethod } from '~/interfaces/Checkout';

const items = ref<CartItem[]>([]);
const promoCode = ref<string | null>(null);
const total = ref<number>(0);
const discount = ref<number>(0);
const shippingRates = ref({
  standard: 0,
  express: 0,
  international: 0,
});
const cartUUID = ref<string | null>(null);
const isCartLoading = ref<boolean>(false);
const isPromoCodeLoading = ref<boolean>(false);
const showMiniCartPreview = ref<boolean>(false);
const showCart = ref<boolean>(false);
const errorPromoCode = ref<string | null>(null);

export const useCartStore = defineStore(
  'cart',
  () => {
    const { $http, $i18n } = useNuxtApp();

    const checkoutStore = useCheckoutStore();

    const computeTotalPrice = () => {
      let subtotal = 0;
      let newDiscount = 0;

      items.value.forEach((item) => {
        const itemPrice = item.sale_price ? item.sale_price : item.price;
        subtotal += itemPrice * item.quantity;
        if (item.discount && item.discount > 0) {
          newDiscount += item.discount * item.quantity;
        }
      });

      discount.value = newDiscount;
      total.value = subtotal - discount.value;
    };

    // Computed
    const subtotal = computed(() =>
      items.value.reduce((total, item) => total + (item.sale_price ? item.sale_price : item.price) * item.quantity, 0),
    );

    const totalItemsCount = computed(() => items.value.reduce((count, item) => count + item.quantity, 0));

    const taxes = computed(() => {
      const taxValue = subtotal.value * 0.05;
      return taxValue;
    });

    const finalTotal = computed(() => {
      let baseTotal = subtotal.value - discount.value;

      if (checkoutStore.address.country === 'AE') {
        switch (checkoutStore.deliveryMethod) {
          case DeliveryMethod.SAMEDAY:
            baseTotal += shippingRates.value.standard;
            break;
          case DeliveryMethod.EXPRESS:
            baseTotal += shippingRates.value.express;
            break;
          default:
            baseTotal += 0;
            break;
        }
      } else {
        baseTotal += shippingRates.value.international;
      }

      if (checkoutStore.paymentMethod === PaymentMethod.COD) {
        baseTotal += 10.0;
      }

      return baseTotal;
    });

    // Methods

    async function addToCart(product: Product, quantity: number = 1, locale: string = 'en') {
      const maxQuantity = 10;
      const existingItem = items.value.find((item) => item.id === product.id);

      if (existingItem) {
        const potentialNewQuantity = existingItem.quantity + quantity;

        if (potentialNewQuantity > maxQuantity) {
          existingItem.quantity = maxQuantity;
        } else {
          existingItem.quantity = potentialNewQuantity;
        }
      }

      isCartLoading.value = true;

      await useFetch('/api/cart/add-to-cart', {
        method: 'POST',
        body: {
          product_id: existingItem ? existingItem.id : product.id,
          quantity: existingItem ? existingItem.quantity : quantity,
          ...(cartUUID.value && { cart_uuid: cartUUID.value }),
        },
        headers: {
          'Accept-Language': locale,
        },

        async onResponse({ response }) {
          if (response.ok) {
            items.value = response._data.data.cart_items.map((cartItem) => ({
              ...cartItem.product,
              quantity: cartItem.quantity,
              discount: cartItem.discount,
            }));
            setCartUUID(response._data.data.cart_uuid);
            isCartLoading.value = false;
            if (promoCode.value) {
              await applyPromoCode(promoCode.value);
              return;
            }
          }
        },
      });

      computeTotalPrice();
    }

    async function deleteFromCart(productId: number) {
      items.value = items.value.filter((item) => item.id !== productId);

      isCartLoading.value = true;

      await useFetch('/api/cart/delete-cart', {
        method: 'DELETE',
        body: {
          product_id: productId,
          cart_uuid: cartUUID.value,
        },
        onResponse() {
          isCartLoading.value = false;
        },
      });

      if (promoCode.value && items.value.length > 0) {
        await applyPromoCode(promoCode.value);
        return;
      }

      if (items.value.length === 0) {
        resetCart();
      }

      computeTotalPrice();
    }

    async function retrieveCart(locale: string = 'en') {
      await useFetch(`/api/cart/${cartUUID.value}`, {
        headers: {
          'Accept-Language': locale,
        },
        onResponse({ response }) {
          if (response.ok) {
            items.value = response._data.data.cart_items.map((cartItem) => ({
              ...cartItem.product,
              quantity: cartItem.quantity,
              discount: cartItem.discount,
            }));
            computeTotalPrice();
          } else {
            resetCart();
          }
        },
      });
    }

    async function updateCart(productId: number, quantity: number) {
      const item = items.value.find((item) => item.id === productId);
      if (item) {
        item.quantity = quantity;
      }

      isCartLoading.value = true;
      try {
        await $http.post('cart', {
          product_id: productId,
          quantity,
          cart_uuid: cartUUID.value ? cartUUID.value : null,
        });

        if (promoCode.value) {
          await applyPromoCode(promoCode.value);
          return;
        }
      } catch (error) {
        console.error('Error updating cart:', error);
      } finally {
        isCartLoading.value = false;
      }

      computeTotalPrice();
    }

    function resetCart(resetPromo = false) {
      if (resetPromo) {
        promoCode.value = null;
      }
      items.value = [];
      total.value = 0;
      discount.value = 0;
      cartUUID.value = null;
    }

    function resetPromoCode() {
      promoCode.value = null;
    }

    async function applyPromoCode(code: string) {
      isPromoCodeLoading.value = true;
      isCartLoading.value = true;

      if (items.value.length === 0) {
        isPromoCodeLoading.value = false;
        errorPromoCode.value = $i18n.t('responses.promo.empty_cart');
        return;
      }

      try {
        const response = await $http.get(`cart/${cartUUID.value}/coupon/${code}/apply`);
        if (response.status == 200) {
          errorPromoCode.value = null;
          const data: Cart = response.data.data;
          if (data) {
            items.value = data.cart_items.map((cartItem) => ({
              ...cartItem.product,
              quantity: cartItem.quantity,
              discount: cartItem.discount,
            }));
          }
          promoCode.value = code;
        }
      } catch (error: any) {
        if (error.response.status === 422) {
          errorPromoCode.value = error.response.data.message;
        } else {
          errorPromoCode.value = $i18n.t('responses.promo.error');
        }
        console.error(error);
      } finally {
        isPromoCodeLoading.value = false;
        isCartLoading.value = false;
      }

      computeTotalPrice();
    }

    async function removePromoCode() {
      isPromoCodeLoading.value = true;
      try {
        if (promoCode.value === null) return;
        const response = await $http.get(`cart/${cartUUID.value}/coupon/remove`);
        if (response.status == 200) {
          const data: Cart = response.data.data;
          if (data) {
            items.value = data.cart_items.map((cartItem) => ({
              ...cartItem.product,
              quantity: cartItem.quantity,
              discount: cartItem.discount,
            }));
            promoCode.value = '';
          }
        }
      } catch (error: any) {
        console.error(error.message);
      } finally {
        isPromoCodeLoading.value = false;
      }

      promoCode.value = null;
      computeTotalPrice();
    }

    function setShowCart(value: boolean) {
      showCart.value = value;
    }

    function setCartUUID(uuid: string) {
      cartUUID.value = uuid;
    }

    return {
      items,
      promoCode,
      total,
      discount,
      shippingRates,
      cartUUID,
      isCartLoading,
      isPromoCodeLoading,
      showMiniCartPreview,
      showCart,
      errorPromoCode,
      setShowCart,
      addToCart,
      deleteFromCart,
      updateCart,
      retrieveCart,
      resetCart,
      resetPromoCode,
      applyPromoCode,
      removePromoCode,
      taxes,
      subtotal,
      totalItemsCount,
      finalTotal,
    };
  },
  {
    persist: [
      {
        key: 'cart',
        paths: ['cartUUID'],
      },
      {
        key: 'promo',
        paths: ['promoCode'],
        storage: persistedState.sessionStorage,
      },
    ],
  },
);
