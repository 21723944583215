<template>
  <component :is="componentToShow" class="shared-button transition-all" :class="computedClass" v-bind="computedArgs">
    <slot />
  </component>
</template>

<script setup lang="ts">
const props = defineProps({
  to: {
    type: [String, Object],
    default: '',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  target: {
    type: String as PropType<'_blank' | '_self' | '_parent' | '_top'>,
    default: '_self',
  },
  type: {
    type: String as PropType<'button' | 'submit' | 'reset'>,
    default: 'button',
  },
  variant: {
    type: String as PropType<'solid' | 'outline'>,
    default: 'solid',
  },
  color: {
    type: String as PropType<'primary' | 'white' | 'custom'>,
    default: 'primary',
  },
  customClass: {
    type: String,
    default: '',
  },
  size: {
    type: String as PropType<'sm' | 'md' | 'lg' | 'xl'>,
    default: 'md',
  },
  rounded: {
    type: String as PropType<'sm' | 'md' | 'lg' | 'xl'>,
    default: 'md',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const computedClass = computed(() => {
  let variantClass;

  switch (props.variant) {
    case 'solid':
      switch (props.color) {
        case 'primary':
          variantClass = 'bg-primary-1000 text-white hover:bg-primary-200 hover:text-primary-1000';
          break;
        case 'white':
          variantClass = 'bg-white text-primary-1000 hover:bg-primary-200 hover:text-primary-1000';
          break;
        case 'custom':
          variantClass = props.customClass;
          break;
      }
      break;
    case 'outline':
      switch (props.color) {
        case 'primary':
          variantClass = 'text-primary-1000 border border-primary-1000 hover:border-primary-300 hover:text-primary-300';
          break;
        case 'white':
          variantClass = 'text-white border border-white hover:border-gray-100 hover:text-gray-100';
          break;
        case 'custom':
          variantClass = props.customClass;
          break;
      }
      break;
  }

  switch (props.size) {
    case 'sm':
      variantClass += ' px-2 py-1 text-xs';
      break;
    case 'md':
      variantClass += ' px-4 py-1.5 text-sm';
      break;
    case 'lg':
      variantClass += ' px-6 py-2 text-base';
      break;
    case 'xl':
      variantClass += ' px-8 py-3 text-lg';
      break;
    default:
      variantClass += ' px-4 py-1.5 text-xs';
      break;
  }

  switch (props.rounded) {
    case 'sm':
      variantClass += ' rounded-sm';
      break;
    case 'md':
      variantClass += ' rounded-md';
      break;
    case 'lg':
      variantClass += ' rounded-lg';
      break;
    case 'xl':
      variantClass += ' rounded-xl';
      break;
    default:
      variantClass += ' rounded-md';
      break;
  }

  return `${variantClass} ${props.loading ? 'animate-pulse' : ''}`;
});

const componentToShow = computed(() => {
  if (props.to && (props.to.length || typeof props.to === 'object')) {
    return resolveComponent('NuxtLink');
  }
  return 'button';
});

const computedArgs = computed(() => {
  if (props.to && (props.to.length || typeof props.to === 'object')) {
    return {
      to: props.to,
      target: props.target,
    };
  }
  return {
    type: props.type,
    disabled: props.disabled,
  };
});
</script>

<style scoped>
.shared-button {
  @apply flex font-medium uppercase text-center;
}
</style>
